import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from './logo-white.png';

class Privacy extends Component {

  render() {
    return (
      <div className='w3-animate-zoom w3-display-container w3-margin w3-center'>
        <Link to='/home' style={{cursor:'pointer'}}><img src={logo} alt='Eventura Logo' style={{height: 200}} /></Link>
        <div style={{width:'90%', margin:'auto'}}>
          <div className='w3-card w3-border w3-display-center w3-round-large w3-padding w3-margin w3-text-white e-opacity'>
            <h2>Eventura Privacy Policy</h2>
            <p>
              This privacy policy ("Policy") describes how Website Developer ("Website Developer", "we", "us" or "our") collects, protects and uses the personally identifiable information ("Personal Information") you ("User", "you" or "your") may provide in the Eventura Website and any of its products or services (collectively, "Website" or "Services"). It also describes the choices available to you regarding our use of your Personal Information and how you can access and update this information. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.
            </p>
            <b>
              Collection of personal information
            </b>
            <p>
              We receive and store any information you knowingly provide to us when you create an account, fill any online forms in the Website. When required this information may include your email address, or other Personal Information. You can choose not to provide us with certain information, but then you may not be able to take advantage of some of the Website's features. Users who are uncertain about what information is mandatory are welcome to contact us.
            </p>
            <b>
              Collection of non-personal information
            </b>
            <p>
              When you open the Website our servers automatically record information that your device sends. This data may include information such as your device's IP address and location, device name and version, operating system type and version, language preferences, information you search for in our Website, access times and dates, and other statistics.
            </p>
            <b>
              Managing personal information
            </b>
            <p>
              You are able to access, add to, update and delete certain Personal Information about you. The information you can view, update, and delete may change as the Website or Services change. When you update information, however, we may maintain a copy of the unrevised information in our records. Some information may remain in our private records after your deletion of such information from your account. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after the expiration of the retention period.
            </p>
            <b>
              Use of collected information
            </b>
            <p>
              Any of the information we collect from you may be used to personalize your experience; improve customer service and respond to queries and emails of our customers; run and operate our Website and Services. Non-Personal Information collected is used only to identify potential cases of abuse and establish statistical information regarding Website traffic and usage. This statistical information is not otherwise aggregated in such a way that would identify any particular user of the system.
            </p>
            <b>
              Privacy of children
            </b>
            <p>
              We do not knowingly collect any Personal Information from children under the age of 13. If you are under the age of 13, please do not submit any Personal Information through our Website or Service. We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through our Website or Service without their permission. If you have reason to believe that a child under the age of 13 has provided Personal Information to us through our Website or Service, please contact us. You must also be old enough to consent to the processing of your personal data in your country (in some countries we may allow your parent or guardian to do so on your behalf).
            </p>
            <b>
              Information security
            </b>
            <p>
              We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in its control and custody. However, no data transmission over the Internet or wireless network can be guaranteed. Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and our Website cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third-party, despite best efforts.
            </p>
            <b>
              Data breach
            </b>
            <p>
              In the event we become aware that the security of the Website has been compromised or users Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the user as a result of the breach or if notice is otherwise required by law. When we do, we will send you an email.
            </p>
            <b>
              Legal disclosure
            </b>
            <p>
              We will disclose any information we collect, use or receive if required or permitted by law, such as to comply with a subpoena, or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request. In the event we go through a business transition, such as a merger or acquisition by another company, or sale of all or a portion of its assets, your user account, and personal data will likely be among the assets transferred.
            </p>
            <b>
              Changes and amendments
            </b>
            <p>
              We reserve the right to modify this Policy relating to the Website or Services at any time, effective upon posting of an updated version of this Policy in the Website. When we do we will revise the updated date at the bottom of this page. Continued use of the Website after any such changes shall constitute your consent to such changes.
            </p>
            <b>
              Acceptance of this policy
            </b>
            <p>
              You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Website and its Services.
            </p>
            <b>
              Contacting us
            </b>
            <div><p>
              If you have any questions about this Policy, please contact us.
            </p></div>
            <p>
              This document was last updated on February 12, 2019
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;
