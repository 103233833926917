import React, { Component } from 'react';
import { auth } from '../Firebase/firebase.js';
import { withRouter } from 'react-router-dom';

class LogoutButton extends Component {

  doSignOut = async () => {
    console.log('logout pressed!');
    try {
      await auth.signOut();
      this.props.history.push('/login');
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <button className='w3-bar-item w3-button' onClick={this.doSignOut}>Logout</button>
    );
  }
}

export default withRouter(LogoutButton);
