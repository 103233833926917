import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo-white.png';
import email from '../images/ic_email.png';
import lock from '../images/ic_lock.png';

const LoginView = ({ onSubmit, resetStyle, handleReset, handleChange, goReset, closeReset, resetEmail }) => {

  return (
    <div className='w3-animate-bottom w3-container w3-center w3-text-white'>
      <div className='w3-display-container w3-margin' >
        <div className='w3-display-center'>
          <Link to='/home' style={{cursor:'pointer'}}><img src={logo} alt='Eventura Logo' style={{height: 200}} /></Link>
        </div>
      </div>
      <div style={{width:'75%',margin:'auto'}}>
        <div className='w3-card w3-border w3-display-center w3-round-large w3-padding w3-margin e-opacity'>
          <header className='w3-container'>
            <h2>Log In</h2>
          </header>
          <form className='w3-container w3-margin' onSubmit={onSubmit} >
            <div className='w3-row w3-margin'>
              <div className='w3-col w3-hide-small' style={{width:50}}><img src={email} alt='ic_email' style={{width:20}} /></div>
              <div className='w3-rest'><input className='w3-input w3-round-large' name='email' type='text' placeholder='Email' /></div>
            </div>
            <div className='w3-row w3-margin' >
              <div className='w3-col w3-hide-small' style={{width:50}}><img src={lock} alt='ic_lock' style={{width:20}} /></div>
              <div className='w3-rest'><input className='w3-input w3-round-large' name='password' type='password' placeholder='Password' /></div>
            </div>
            <button className='w3-button w3-round-large w3-red' type='submit'>Sign In</button>
          </form>
          <Link className='w3-margin' to='/signup'>Sign up for access</Link>
          <p className='w3-margin w3-text-red' style={{cursor:'pointer'}} onClick={goReset}>Forgot/reset password?</p>
          <div className='w3-modal d-opacity' style={resetStyle}>
            <div className='w3-modal-content w3-eventura w3-animate-zoom w3-card-4'>
              <form onSubmit={handleReset}>
                <header className='w3-container w3-eventura'>
                  <h3>Reset Eventura Password</h3>
                </header>
                <div className='w3-container' style={{height:200}}>
                  <div className='w3-display-middle'>
                    <p>Please enter your Eventura email address:</p>
                    <div><input className='w3-input w3-round-large' type='text' value={resetEmail} onChange={(e) => handleChange(e)} /></div>
                  </div>
                </div>
                <footer className='w3-container w3-row'>
                  <div className='w3-margin'>
                    <div className='w3-half w3-padding'><button className='w3-button w3-block w3-round-large w3-grey w3-text-white' onClick={closeReset}>Cancel</button></div>
                    <div className='w3-half w3-padding'><button className='w3-button w3-block w3-round-large w3-red w3-text-white' type='submit'>Reset!</button></div>
                  </div>
                </footer>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
