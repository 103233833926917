import React from 'react';
import { Link } from 'react-router-dom';
import Datetime from 'react-datetime';
import '../../react-datetime.css';

import logo from '../../logo-white.png';
// import menu from '../../images/ic_menu.png';
import check from '../../images/check.png';
import help from '../../images/ic_help.png';

const CreateEvent = ({
  goInfo, goDetails, goSections, goBackground, setInfo, setDetails, setSections, setBackground,
  infoStyle, detailsStyle, sectionsStyle, backgroundStyle, infoHeadStyle, detailsHeadStyle, sectionsHeadStyle, backgroundHeadStyle,
  infoCheck, detailsCheck, sectionsCheck, handleChange, handleStartChange, handleEndChange, handleSectionChange, handleBackground, handleBgSelect, handleBgChoice,
  name, mode, type, limit, start, end, email, phone, description, city, state, zip, name1, email1, phone1, name2, email2, phone2,
  section1, section2, section3, section4, custom, defaultBG1, defaultBG2, bg1Style, bg2Style, bgMessage, defaultBgStyle, customBgStyle,
  closeModal, goModeHelp, goTypeHelp, goLimitHelp,
  modeStyle, typeStyle, limitStyle,
}) => {

  return (
    <div>
      <div className='w3-top w3-border-bottom e-opacity'>
        <div className='w3-bar w3-display-container'>
          <div className='w3-bar-item w3-left'>
            <Link to='/' style={{cursor:'pointer'}}><img className='w3-left' src={logo} alt='Eventura logo' style={{height:75}} /></Link>
            <span className='w3-margin w3-left w3-xlarge w3-text-white'>EVENTURA</span>
          </div>
        </div>
      </div>
      <div style={{height:150}}></div>
      <div style={{width:'75%',margin:'auto'}}>
        <div className='w3-card w3-display-center w3-round-large w3-margin w3-border w3-text-white e-opacity'>
          <header className='w3-container w3-center'>
            <h2>Create Event</h2>
          </header>
          <div className='w3-row w3-eventura'>
            <div className='w3-quarter w3-center w3-padding' style={infoHeadStyle} onClick={goInfo}>Information <img src={check} style={infoCheck} alt='checkmark' /></div>
            <div className='w3-quarter w3-center w3-padding' style={detailsHeadStyle} onClick={goDetails}>Details <img src={check} style={detailsCheck} alt='checkmark' /></div>
            <div className='w3-quarter w3-center w3-padding' style={sectionsHeadStyle} onClick={goSections}>Sections <img src={check} style={sectionsCheck} alt='checkmark' /></div>
            <div className='w3-quarter w3-center w3-padding' style={backgroundHeadStyle} onClick={goBackground}>Background </div>
          </div>
          <div className='' style={infoStyle}>
            <form className='w3-container w3-margin' onSubmit={setInfo} >
              <div className='w3-container w3-center'><h3>Event General Information</h3></div>
              <div className='w3-container w3-center'><p>All fields with * are required to be completed before you can continue.</p></div>
              <div className='w3-container w3-margin' >
                <div className='w3-center'><label>Event Name: *</label></div>
                <input className='w3-input w3-round-large' type='text' placeholder='Event Name' required name='name' value={name} onChange={(e) => handleChange(e)}/>
              </div>
              <div className='w3-row-padding w3-margin'>
                <div className='w3-third'>
                  <label>Mode: * <button className='w3-button w3-circle w3-tiny' onClick={goModeHelp}><img src={help} alt='ic_help' style={{width:20,height:20,cursor:'pointer'}} /></button></label>
                  <select className='w3-select w3-round-large' required name='mode' value={mode} onChange={(e) => handleChange(e)}>
                    <option value='' defaultValue></option>
                    <option value='Private'>Private</option>
                    <option value='Public'>Public</option>
                  </select>
                </div>
                <div className='w3-modal e-opacity' style={modeStyle}>
                  <div className='w3-modal-content w3-eventura w3-animate-zoom w3-card-4 w3-border w3-round-large w3-padding'>
                    <div className='w3-container' style={{height:200}}>
                      <h3 className='w3-center'>Event Mode</h3>
                      <p><b>Public Mode</b> allows your event to be visible to all Eventura mobile app users on the main page. All users will have the ability to register for your event.</p>
                      <p><b>Private Mode</b>  prevents your event from being visible to all Eventura mobile app users. You will be able to invite guests by providing the system generated Event Code.</p>
                      <button className='w3-button w3-display-bottommiddle w3-round' onClick={closeModal}>Close</button>
                    </div>
                  </div>
                </div>
                <div className='w3-third'>
                  <label>Type: * <button className='w3-button w3-circle w3-tiny' onClick={goTypeHelp}><img src={help} alt='ic_help' style={{width:20,height:20,cursor:'pointer'}} /></button></label>
                  <select className='w3-select w3-round-large' required name='type' value={type} onChange={(e) => handleChange(e)}>
                    <option value='' defaultValue></option>
                    <option value='Business'>Business</option>
                    <option value='Family'>Family</option>
                    <option value='Religious'>Religious</option>
                    <option value='Social'>Social</option>
                    <option value='Wedding'>Wedding</option>
                  </select>
                </div>
                <div className='w3-modal e-opacity' style={typeStyle}>
                  <div className='w3-modal-content w3-eventura w3-animate-zoom w3-card-4 w3-border w3-round-large w3-padding'>
                    <div className='w3-container' style={{height:200}}>
                      <h3 className='w3-center'>Event Type</h3>
                      <p><b>Type</b> determines which default backgrounds you can select from to be displayed as the background for your event within the Eventura mobile app.
                      Don't worry, you'll also have the option to upload your own custom background that suits your need.</p>
                      <p>In a future build, if your event is "Public", users will be able to filter/sort by Event Type.</p>
                      <button className='w3-button w3-display-bottommiddle w3-round' onClick={closeModal}>Close</button>
                    </div>
                  </div>
                </div>
                <div className='w3-third'>
                  <label>Guest Limit: * <button className='w3-button w3-circle w3-tiny' onClick={goLimitHelp}><img src={help} alt='ic_help' style={{width:20,height:20,cursor:'pointer'}} /></button></label>
                  <select className='w3-select w3-round-large' required name='limit' value={limit} onChange={(e) => handleChange(e)}>
                    <option value='' defaultValue></option>
                    <option value='50'>Less than 50</option>
                    <option value='100'>50 - 100</option>
                    <option value='200'>Over 100</option>
                  </select>
                </div>
              </div>
              <div className='w3-modal e-opacity' style={limitStyle}>
                <div className='w3-modal-content w3-eventura w3-animate-zoom w3-card-4 w3-border w3-round-large w3-padding'>
                  <div className='w3-container' style={{height:200}}>
                    <h3 className='w3-center'>Event Guest Limit</h3>
                    <p><b>Guest Limit</b> is a range of your anticipated attendees for your event.</p>
                    <p>In future build, registration for you event will close once the limit is reached.</p>
                    <button className='w3-button w3-display-bottommiddle w3-round' onClick={closeModal}>Close</button>
                  </div>
                </div>
              </div>
              <div className='w3-row-padding w3-margin w3-center'>
                <div className='w3-half'>
                  <label>Start Date: *</label>
                  <Datetime value={start} timeFormat={false} dateFormat='MMMM DD, YYYY' onChange={(e) => handleStartChange(e)}>
                    <input name='start' type='text' readOnly/>
                  </Datetime>
                </div>
                <div className='w3-half'>
                  <label>End Date: *</label>
                  <Datetime value={end} timeFormat={false} dateFormat='MMMM DD, YYYY' onChange={(e) => handleEndChange(e)}>
                    <input name='end' type='text' readOnly/>
                  </Datetime>
                </div>
              </div>
              <div className='w3-container w3-center'><h3>Point of Contact</h3></div>
              <div className='w3-row-padding w3-margin'>
                <div className='w3-third'>
                  <label>Name 1: *</label>
                  <input className='w3-input w3-round-large w3-cell' type='text' placeholder='Name' required name='name1' value={name1} onChange={(e) => handleChange(e)}/>
                </div>
                <div className='w3-third'>
                  <label>Email 1: *</label>
                  <input className='w3-input w3-round-large w3-cell' type='email' placeholder='Email' required name='email1' value={email1} onChange={(e) => handleChange(e)}/>
                </div>
                <div className='w3-third'>
                  <label>Phone 1: *</label>
                  <input className='w3-input w3-round-large w3-cell' type='number' min='10' placeholder='Phone' required name='phone1' value={phone1} onChange={(e) => handleChange(e)}/>
                </div>
              </div>
              <div className='w3-row-padding w3-margin w3-border-top'>
                <div className='w3-third'>
                  <label>Name 2:</label>
                  <input className='w3-input w3-round-large w3-cell' type='text' placeholder='Name' name='name2' value={name2} onChange={(e) => handleChange(e)}/>
                </div>
                <div className='w3-third'>
                  <label>Email 2:</label>
                  <input className='w3-input w3-round-large w3-cell' type='email' placeholder='Email' name='email2' value={email2} onChange={(e) => handleChange(e)}/>
                </div>
                <div className='w3-third'>
                  <label>Phone 2:</label>
                  <input className='w3-input w3-round-large w3-cell' type='number' min='10' max='15' placeholder='Phone' name='phone2' value={phone2} onChange={(e) => handleChange(e)}/>
                </div>
              </div>
              <div className='w3-container w3-center w3-margin'>
                <button className='w3-button w3-round-large w3-red' type="submit">Save Event General Information</button>
              </div>
            </form>
          </div>
          <div className='w3-animate-right' style={detailsStyle}>
            <form className='w3-container w3-margin' onSubmit={setDetails} >
              <div className='w3-container w3-center'><h3>{name} Event Details</h3></div>
              <div className='w3-container w3-margin' >
                <div className='w3-center'><label>Event Description: *</label></div>
                <textarea className='w3-input w3-round-large' rows='5' required name='description' value={description} onChange={(e) => handleChange(e)}/>
              </div>
              <div className='w3-container w3-center'><h3>Event Location</h3></div>
              <div className='w3-container w3-margin'>
                <div><label>City: *</label></div>
                <input className='w3-input w3-round-large w3-cell' type='text' placeholder='City' required name='city' value={city} onChange={(e) => handleChange(e)}/>
                <div><label>State: *</label></div>
                <select className='w3-select w3-round-large w3-cell' required name='state' value={state} onChange={(e) => handleChange(e)}>
                  <option value=""></option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
                <div><label>Zip Code: *</label></div>
                <input className='w3-input w3-round-large w3-cell' type='number' min='5' placeholder='Zip Code' required name='zip' value={zip} onChange={(e) => handleChange(e)}/>
              </div>
              <div className='w3-container w3-center w3-margin'>
                <button className='w3-button w3-round-large w3-red' type="submit">Save Event Details</button>
              </div>
            </form>
          </div>
          <div className='w3-animate-right' style={sectionsStyle}>
            <form className='w3-container w3-margin' onSubmit={setSections} >
              <div className='w3-container w3-center'><h3>Mobile App Sections</h3></div>
              <div className='w3-container w3-margin'>
                <div><label>Section1:</label></div>
                <select className='w3-select w3-round-large' name='section1' value='Schedule' readOnly>
                  <option value='Schedule' defaultValue>Schedule (selected by default)</option>
                </select>
                <div><label>Section2:</label></div>
                <select className='w3-select w3-round-large' name='section2' value={section2} onChange={(e) => handleSectionChange(e)}>
                  <option value='' defaultValue></option>
                  <option value='Dining'>Dining</option>
                  <option value='Speaker'>Speaker</option>
                  <option value='Sponsor'>Sponsor</option>
                  <option value='Travel'>Travel</option>
                </select>
                <div><label>Section3:</label></div>
                <select className='w3-select w3-round-large' name='section3' value={section3} onChange={(e) => handleSectionChange(e)}>
                  <option value='' defaultValue></option>
                  <option value='Dining'>Dining</option>
                  <option value='Speaker'>Speaker</option>
                  <option value='Sponsor'>Sponsor</option>
                  <option value='Travel'>Travel</option>
                </select>
                <div><label>Section4:</label></div>
                <select className='w3-select w3-round-large' name='section4' value={section4} onChange={(e) => handleSectionChange(e)}>
                  <option value='' defaultValue></option>
                  <option value='Dining'>Dining</option>
                  <option value='Speaker'>Speaker</option>
                  <option value='Sponsor'>Sponsor</option>
                  <option value='Travel'>Travel</option>
                </select>
                <div><label>Custom Section:</label></div>
                <input className='w3-input w3-round-large' type='text' placeholder='Custom Section' name='custom' value={custom} onChange={(e) => handleSectionChange(e)}/>
              </div>
              <div className='w3-container w3-center w3-margin-bottom'>
                <button className='w3-button w3-round-large w3-red' type="submit">Save App Sections</button>
              </div>
            </form>
          </div>
          <div className='w3-animate-right' style={backgroundStyle}>
            <form className='w3-container w3-margin' onSubmit={setBackground} >
              <div className='w3-container w3-center'><h3>Event Background</h3></div>
              <div className='w3-container w3-margin w3-center'>
                <p>Select an image for the background you would like displayed in the Eventura App for this event.</p>
                <div style={defaultBgStyle}>
                  <div><label>Choose from default images:</label></div>
                  <div className='w3-row-padding w3-margin w3-lightgrey'>
                    <div className='w3-half w3-hover-red w3-padding' style={bg1Style} ><img className='w3-border w3-hover-grayscale maxWidth' src={defaultBG1} name='BG1' value={defaultBG1} alt='background image1' onClick={(e) => handleBgSelect(e)}/></div>
                    <div className='w3-half w3-hover-red w3-padding' style={bg2Style} ><img className='w3-border w3-hover-grayscale maxWidth' src={defaultBG2} name='BG2' value={defaultBG2} alt='background image2' onClick={(e) => handleBgSelect(e)}/></div>
                  </div>
                </div>
                <div style={customBgStyle}>
                  <div><label>Upload your own background image:</label></div>
                  <div className='w3-container w3-center'><input className='w3-input w3-center' type='file' name='background' onChange={(e) => handleBackground(e)}/></div>
                </div>
                <div><button className='w3-button w3-round-large w3-eventura' onClick={handleBgChoice}>{bgMessage}</button></div>
              </div>
              <div className='w3-container w3-center w3-margin-bottom'>
                <button className='w3-button w3-round-large w3-red' type="submit">Save Event Background</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateEvent;
