import React, { Component } from 'react';
import firebase, { auth, store } from '../Firebase/firebase.js'

import SignUpView from './SignUpView';

class SignUpContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      first: '',
      last: '',
      userId: '',
      photoUrl: '',
    }
    this.ref = firebase.firestore().collection('users');
    this.ref2 = firebase.firestore().collection('webusers');
    this.handleChange = this.handleChange.bind(this);
    // this.handlePhotoChange = this.handlePhotoChange.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.uploadUser = this.uploadUser.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // handlePhotoChange(e) {
  //   let photoUrl;
  //   photoUrl = e.target.files[0];
  //   this.setState({photoUrl: photoUrl});
  // }

  handleSignUp(e) {
    e.preventDefault();
    const { userId, email, password, photoUrl } = this.state;
    auth.createUserWithEmailAndPassword(email, password)
    .catch((e) => {
        alert('update error: ', e.message);
    }).then(() => {
      setTimeout(() => {
        this.getUserData(e);
      }, 1000)
    })
    if (photoUrl) {
      const uploadTask = store.ref('images/profile/').child(userId).child('/profile.jpg').put(photoUrl);
      uploadTask.on('state_changed', (snapshot) => {
        console.log('state changed...');
      }, (error) => {
        console.log(error);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          auth.currentUser.updateProfile({photoURL: downloadURL});
          console.log('photo location: ', downloadURL);
        })
      })
    }
  }

  getUserData(e) {
    const { first, last, photoUrl } = this.state;
    var name = first + ' ' + last;
    var user = auth.currentUser;
    var id;
    if (user != null) {
      id = user.uid;
      this.setState({ userId: id });
      user.updateProfile({ displayName: name, photoUrl: photoUrl })
      .then(() => {
        console.log('Update successful');
      }).catch((error) => {
        console.log('Error: ', error);
      })
    }
    this.uploadUser();
  }

  uploadUser() {
    var data = {
      uid: this.state.userId,
      first: this.state.first,
      last: this.state.last,
      email: this.state.email,
      role: 'coordinator'
    }
    console.log('data: ', data);
    const { userId } = this.state;
    this.ref.doc(userId).set(data).then((doc) => {
      console.log('User saved');
    })
    .catch((error) => {
      console.log('Error adding user', error);
    }).then(() => {
      this.ref2.doc(userId).set(data).then((doc) => {
        console.log('User saved to webuser');
      }).catch((error) => {
        console.log('Error: ', error);
      })
    })
    this.props.history.push('/');
  }

  render() {
    return (<SignUpView onChange={this.handleChange} onSubmit={this.handleSignUp} />);
  }
}

export default SignUpContainer;
