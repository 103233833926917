import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import firebase from '../../Firebase';
import moment from 'moment';

import CustomAdd from './CustomAdd';
import CustomEdit from './CustomEdit';

import logo from '../../logo-white.png';
import menu from '../../images/ic_menu.png';
import close from '../../images/ic_close.png';
import add from '../../images/ic_add.png';

class CustomMain extends Component {
  constructor(props) {
    super(props);
    this.ref = firebase.firestore().collection('events');
    this.unsubscribe = null;
    this.state = {
      mainView: true, addView: false, editView: false, deleteView: false,
      allCustoms: [],
      customsView: '',
      selectedCustom: '',
      customID:'', customName:'', phone:'', email:'', url:'', description:'', timeStamp:moment(),
      deleteStyle: {display:'none'},
    }
    this.goMain = this.goMain.bind(this);
    this.goAdd = this.goAdd.bind(this);
    this.goEdit = this.goEdit.bind(this);
    this.goDelete = this.goDelete.bind(this);
    this.closeDelete = this.closeDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
  }

  getCustoms = () => {
    const {eventID, customLabel} = this.props;
    const {goEdit, goDelete} = this;
    var newCustoms = [];
    var customView = '';
    var query = this.ref.doc(eventID).collection('custom').where('eventID', '==', eventID);
    query.orderBy('timeStamp').get().then((snapshot) => {
      snapshot.forEach((doc) => {
        const { customID, eventID, eventName, customName, phone, email, url, description } = doc.data();
        newCustoms.push({
          customID, eventID, eventName, customName, phone, email, url, description
        });
      });
    });
    setTimeout(() => {
      if (newCustoms.length > 0) {
        this.setState({allCustoms:newCustoms});
        const {allCustoms} = this.state;
        customView = allCustoms.map(custom => (
          <div className='w3-container w3-margin' key={custom.customID}>
            <div className='w3-center w3-margin-bottom'><h4>{customLabel} Entry: {custom.customName}</h4></div>
            <div className='w3-row-padding w3-margin-top'>
              <div className='w3-threequarter'><div className='w3-left-align w3-margin-top'>Details: {custom.description}</div></div>
              <div className='w3-quarter'>
                <button className='w3-btn w3-block w3-round-large w3-grey w3-text-white w3-margin-bottom' onClick={goEdit} value={custom.customID} >Edit</button>
                <button className='w3-btn w3-block w3-round-large w3-red w3-text-white' onClick={goDelete} value={custom.customID}>Delete</button>
              </div>
            </div>
          </div>
        ))
        this.setState({customsView:customView});
      } else {
        customView = <div className='w3-container w3-margin'><p>Click the "+" above to add your first {customLabel} entry</p></div>
        this.setState({customsView:customView});
      }
    }, 500)
  }

  componentDidMount() {
    this.unsubscribe = this.getCustoms();
  }

  componentWillUnmount() {
    this.unsubscribe = null;
  }

  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  handleSubmit(e) {
    e.preventDefault();
    const {timeStamp} = this.state;
    const {eventID} = this.props;
    var newStamp = timeStamp.format();
    var data = {
      eventID: this.props.eventID,
      eventName: this.props.eventName,
      customName: this.state.customName,
      email: this.state.email,
      url: this.state.url,
      timeStamp: newStamp,
      description: this.state.description,
    }
    setTimeout(() => {
      var custID;
      this.ref.doc(eventID).collection('custom').add(data).then((docRef) => {
        console.log('custom saved');
        custID = docRef.id;
        this.setState({customID: custID});
      }).catch((error) => {
        console.error('error adding custom: ',error);
      }).then(() => {
        this.ref.doc(eventID).collection('custom').doc(custID).update({
          customID: custID
        });
        this.setState({customID:custID});
      })
      this.unsubscribe = null;
      this.goMain(e);
    }, 500)
  }

  handleEditChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  handleEditSubmit(e) {
    e.preventDefault();
    const {customID} = this.state;
    const {eventID} = this.props;
    var data = {
      eventID: this.props.eventID,
      eventName: this.props.eventName,
      customName: this.state.customName,
      email: this.state.email,
      url: this.state.url,
      timeStamp: this.state.timeStamp,
      description: this.state.description,
    }
    setTimeout(() => {
      this.ref.doc(eventID).collection('custom').doc(customID).update(data);
    }, 100)
    this.unsubscribe = null;
    this.goMain(e);
  }

  handleDelete(e) {
    e.preventDefault();
    const {customID} = this.state;
    const {eventID} = this.props;
    this.ref.doc(eventID).collection('custom').doc(customID).delete().then(() => {
      console.log('custom deleted');
      this.setState({deleteStyle: {display:'none'},});
    }).catch((error) => {
      console.error('error deleting custom');
    })
    this.unsubscribe = null;
    this.goMain(e);
  }

  goMain(e) {
    e.preventDefault();
    this.unsubscribe = this.getCustoms();
    setTimeout(() => {
      this.setState({
        mainView: true,
        addView: false,
        editView: false,
        deleteView: false,
      })
    }, 500)
  }

  goAdd(e) {
    e.preventDefault();
    this.setState({
      mainView: false,
      addView: true,
      editView: false,
      deleteView: false,
      customName:'', phone:'', email:'', url:'', description:'', timeStamp:moment(),
    })
  }

  goEdit(e) {
    e.preventDefault();
    const {allCustoms} = this.state;
    const key = e.target.value;
    allCustoms.forEach((doc) => {
      if (doc.customID === key) {
        this.setState({
          selectedCustom: doc,
          customID: doc.customID,
          customName: doc.customName,
          email: doc.email,
          url: doc.url,
          timeStamp: doc.timeStamp,
          description: doc.description,
        })
      }
    })
    setTimeout(() => {
      this.setState({
        mainView: false,
        addView: false,
        editView: true,
        deleteView: false,
      })
    }, 500)
  }

  goDelete(e) {
    e.preventDefault();
    const {allCustoms} = this.state;
    const key = e.target.value;
    allCustoms.forEach((doc) => {
      if (doc.customID === key) {
        this.setState({
          selectedCustom: doc,
          customID: doc.customID,
          customName: doc.customName,
          phone: doc.phone,
          email: doc.email,
          url: doc.url,
          timeStamp: doc.timeStamp,
          description: doc.description,
        })
      }
    })
    this.setState({
      deleteView: true,
      deleteStyle: {display:'block'},
    })
  }

  closeDelete(e) {
    e.preventDefault();
    this.setState({deleteStyle: {display:'none'},});
  }

  render() {
    const {eventName, eStart, eEnd, selectedBG, goView, customLabel,} = this.props;
    const {mainView, addView, editView, customsView, deleteStyle, customName, description} = this.state;
    const {goAdd, closeDelete, handleDelete} = this;
    let view;
    if (mainView) {
      view = (
        <div>
          <div className='w3-top w3-border-bottom e-opacity'>
            <div className='w3-bar w3-display-container'>
              <div className='w3-bar-item w3-left'>
                <Link to='/' style={{cursor:'pointer'}}><img className='w3-left' src={logo} alt='Eventura logo' style={{height:75}} /></Link>
                <span className='w3-margin w3-left w3-xlarge w3-text-white'>EVENTURA</span>
              </div>
              <img src={menu} className='w3-bar-item w3-right w3-margin-top' alt='ic_menu' style={{height:40}} />
            </div>
          </div>
          <div style={{height:150}}></div>
          <div style={{width:'75%',margin:'auto'}}>
            <div className='w3-card w3-display-center w3-round-large w3-margin w3-border w3-text-white e-opacity w3-animate-zoom'>
              <header className='w3-display-container w3-center'>
                <div className='w3-display-topright w3-margin'><button className='w3-button w3-circle w3-red' onClick={goView}>
                  <img src={close} alt='ic_close' style={{width:15,height:15}} />
                </button></div>
                <div className='w3-container w3-center'><h2>{eventName} {customLabel}</h2></div>
              </header>
              <div className='w3-display-container'>
                <div className='w3-round-large'>
                  <img className='w3-opacity-min' src={selectedBG} alt='background' style={{height:200,width:'100%'}} />
                </div>
                <div className='w3-display-topmiddle' style={{width:'90%'}}>
                  <h3 className='w3-center'>Event Dates: {eStart} - {eEnd}</h3>
                  <h3 className='w3-center'>{eventName} {customLabel}</h3>
                  <div className='w3-center'><button className='w3-button w3-circle w3-eventura'>
                    <img src={add} onClick={goAdd} alt='ic_add' style={{width:20,height:20}} />
                  </button></div>
                </div>
                <div className='w3-modal e-opacity' style={deleteStyle}>
                  <div className='w3-modal-content w3-eventura w3-animate-zoom w3-card-4'>
                    <header className='w3-container w3-indigo'>
                      <span className='w3-button w3-xlarge w3-display-topright' onClick={closeDelete}>&times;</span>
                      <h3>Are you sure you want to delete {customName}?</h3>
                    </header>
                    <div className='w3-container' style={{height:200}}>
                      <div className='w3-display-middle'><p>{description}</p></div>
                    </div>
                    <footer className='w3-container w3-row'>
                      <div className='w3-margin'>
                        <div className='w3-half w3-padding'><button className='w3-button w3-block w3-round-large w3-grey w3-text-white' onClick={closeDelete}>No, go back!</button></div>
                        <div className='w3-half w3-padding'><button className='w3-button w3-block w3-round-large w3-red w3-text-white' onClick={handleDelete}>Yes, delete!</button></div>
                      </div>
                    </footer>
                  </div>
                </div>
                <div className='w3-card e-opacity w3-container w3-margin w3-center' >
                {customsView}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (addView) {
      view = (
        <div>
          <CustomAdd
            goMain={this.goMain} eventName={this.props.eventName} handleChange={this.handleChange} handleSubmit={this.handleSubmit}
            customLabel={this.props.customLabel} customName={this.state.customName} phone={this.state.phone} email={this.state.email} url={this.state.url} description={this.state.description}
          />
        </div>
      )
    } else if (editView) {
      view = (
        <div>
          <CustomEdit
            goMain={this.goMain} eventName={this.props.eventName} handleEditChange={this.handleEditChange} handleEditSubmit={this.handleEditSubmit}
            selectedCustom={this.state.selectedCustom} customLabel={this.props.customLabel} customName={this.state.customName} phone={this.state.phone} email={this.state.email} url={this.state.url} description={this.state.description}
          />
        </div>
      )
    }

    return (
      view
    );
  }
}

export default CustomMain;
