import React from 'react';
import { Link } from 'react-router-dom';

import LogoutButton from '../Logout';
import logo from '../logo-white.png';
import menu from '../images/ic_menu.png';
import profile from '../images/ic_person.png';
import events from '../images/ic_event.png';
import settings from '../images/ic_settings.png';

const MainView = ({
  openMenu, closeMenu, menuStyle,
}) => {

  return (
    <div className='w3-animate-bottom'>
      <div className='w3-top w3-border-bottom e-opacity'>
        <div className='w3-bar w3-display-container'>
          <div className='w3-bar-item w3-left'>
            <img className='w3-left' src={logo} alt='Eventura logo' style={{height:75}} />
            <span className='w3-margin w3-left w3-xlarge w3-text-white'>EVENTURA</span>
          </div>
          <img src={menu} className='w3-bar-item w3-right w3-margin-top' alt='ic_menu' onClick={openMenu} style={{height:40}} />
          <nav className='w3-animate-right w3-sidebar w3-bar-block w3-card w3-border w3-eventura' style={menuStyle}>
            <p className='w3-bar-item w3-button' onClick={closeMenu}>Close</p>
            <LogoutButton/>
          </nav>
        </div>
      </div>
      <div style={{height:200}}></div>
      <div className='w3-row-padding w3-text-white'>
        <div className='w3-third'>
          <div className='w3-card w3-border w3-display-container w3-margin w3-padding w3-round-large e-opacity' style={{height:300}}>
            <h3 className='w3-display-topmiddle'>Events</h3>
            <div className='w3-display-middle w3-center'>
              <p>Go here to create a new event and manage existing events.</p>
            </div>
            <Link to='/events'><button className='w3-display-bottommiddle w3-margin-bottom w3-button w3-circle w3-eventura'>
              <img src={events} alt='ic_event' style={{height:25}}/>
            </button></Link>
          </div>
        </div>
        <div className='w3-third'>
          <div className='w3-card w3-border w3-display-container w3-margin w3-padding w3-round-large e-opacity' style={{height:300}}>
            <h3 className='w3-display-topmiddle'>Profile</h3>
            <div className='w3-display-middle w3-center'>
              <p>Go here to view and modify your Eventura profile.</p>
            </div>
            <div className='w3-display-middle w3-xlarge'><mark className='w3-text-red'>FUTURE</mark></div>
            <button className='w3-display-bottommiddle w3-margin-bottom w3-button w3-hover-eventura w3-circle w3-eventura'>
              <img src={profile} alt='ic_person' style={{height:20}}/>
            </button>
          </div>
        </div>
        <div className='w3-third'>
          <div className='w3-card w3-border w3-display-container w3-margin w3-padding w3-round-large e-opacity' style={{height:300}}>
            <h3 className='w3-display-topmiddle'>Settings</h3>
            <div className='w3-display-middle w3-center'>
              <p>Go here to manage your account settings (like changing your password).</p>
            </div>
            <div className='w3-display-middle w3-xlarge'><mark className='w3-text-red'>FUTURE</mark></div>
            <button className='w3-display-bottommiddle w3-margin-bottom w3-button w3-hover-eventura w3-circle w3-eventura'>
              <img src={settings} alt='ic_settings' style={{height:25}}/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainView;
