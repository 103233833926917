import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../logo-white.png';
import menu from '../../images/ic_menu.png';
import close from '../../images/ic_close.png';

const SpeakerEdit = ({
  goMain, handleEditChange, handleEditSubmit,
  eventName, selectedSpeaker, speakerName, first, last, title, company, phone, email, url, bio,
}) => {

  return (
    <div>
      <div className='w3-top w3-border-bottom e-opacity'>
        <div className='w3-bar w3-display-container'>
          <div className='w3-bar-item w3-left'>
            <Link to='/' style={{cursor:'pointer'}}><img className='w3-left' src={logo} alt='Eventura logo' style={{height:75}} /></Link>
            <span className='w3-margin w3-left w3-xlarge w3-text-white'>EVENTURA</span>
          </div>
          <img src={menu} className='w3-bar-item w3-right w3-margin-top' alt='ic_menu' style={{height:40}} />
        </div>
      </div>
      <div style={{height:150}}></div>
      <div style={{width:'75%',margin:'auto'}}>
        <div className='w3-card w3-display-center w3-round-large w3-margin w3-border w3-text-white e-opacity w3-animate-zoom'>
          <header className='w3-display-container w3-center'>
            <div className='w3-display-topright w3-margin'>
              <button className='w3-button w3-circle w3-red'><img src={close} onClick={goMain} alt='close' style={{width:15,height:15}} /></button>
            </div>
            <div className='w3-container w3-center'><h2>Edit {eventName} Speaker</h2></div>
          </header>
          <div>
            <form className='w3-container w3-margin' onSubmit={handleEditSubmit} >
              <div className='w3-container w3-center'><h3>Speaker Information</h3></div>
              <div className='w3-container w3-margin' >
                <div className='w3-half'>
                  <div className='w3-center'><label>First Name: *</label></div>
                  <input className='w3-input w3-round-large' type='text' placeholder='First name' name='first' value={first} required onChange={(e) => handleEditChange(e)}/>
                </div>
                <div className='w3-half'>
                  <div className='w3-center'><label>Last Name: *</label></div>
                  <input className='w3-input w3-round-large' type='text' placeholder='Last name' name='last' value={last} required onChange={(e) => handleEditChange(e)}/>
                </div>
              </div>
              <div className='w3-container w3-margin' >
                <div className='w3-half'>
                  <div className='w3-center'><label>Title:</label></div>
                  <input className='w3-input w3-round-large' type='text' placeholder='Title' name='title' value={title} onChange={(e) => handleEditChange(e)}/>
                </div>
                <div className='w3-half'>
                  <div className='w3-center'><label>Company:</label></div>
                  <input className='w3-input w3-round-large' type='text' placeholder='Company' name='company' value={company} onChange={(e) => handleEditChange(e)}/>
                </div>
              </div>
              <div className='w3-container w3-margin' >
                <div className='w3-center'><label>Website:</label></div>
                <input className='w3-input w3-round-large' type='url' placeholder='Website' name='url' value={url} onChange={(e) => handleEditChange(e)}/>
              </div>
              <div className='w3-row-padding w3-margin' >
                <div className='w3-half'>
                  <div className='w3-center'><label>Phone:</label></div>
                  <input className='w3-input w3-round-large' type='tel' placeholder='Phone' name='phone' value={phone} onChange={(e) => handleEditChange(e)}/>
                </div>
                <div className='w3-half'>
                  <div className='w3-center'><label>Email:</label></div>
                  <input className='w3-input w3-round-large' type='email' placeholder='Email' name='email' value={email} onChange={(e) => handleEditChange(e)}/>
                </div>
              </div>
              <div className='w3-container w3-margin' >
                <div className='w3-center'><label>Speaker Bio: *</label></div>
                <textarea className='w3-input w3-round-large' rows='5' name='bio' value={bio} required onChange={(e) => handleEditChange(e)}/>
              </div>
              <div className='w3-container w3-center w3-margin'>
                <button className='w3-button w3-round-large w3-red' type="submit">Save Speaker Info</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpeakerEdit;
