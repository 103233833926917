import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo-white.png';
import graphic from '../images/homeGraphic.png';

class HomeView extends Component {

  render() {
    return (
      <div className='w3-animate-zoom w3-display-container w3-margin w3-center'>
        <img src={logo} alt='Eventura Logo' style={{height: 200}} />
        <div style={{width:'90%', margin:'auto'}}>
          <div className='w3-card w3-border w3-display-center w3-round-large w3-padding w3-margin w3-text-white e-opacity'>
            <h2>Welcome to Eventura!</h2>
            <div style={{width:'90%', margin:'auto'}}>
              <mark>
                Eventura is currently in Testing Phase, therefore many of the features are in the process of being added or enhanced.
                We appreciate your business, and are driven to ensure that you are satisfied with your experience using our service.
              </mark>
              <p>
                Eventura is a service designed to provide benefit for both event and conference organizers and their attendees by offering
                access to every aspect of the event at the palm of their hands, in real time.
              </p>
            </div>
            <img src={graphic} alt='Eventura graphic' style={{width:'90%',margin:'auto'}} />
            <p><Link className='w3-button w3-red w3-round-large' to='/login'>Get started with Eventura</Link></p>
            <div className='w3-row-padding w3-margin'>
              <Link className='w3-third w3-text-small' to='/privacy'>Eventura Privacy Policy</Link>
              <Link className='w3-third' to='/terms'>Eventura Terms and Conditions</Link>
              <Link className='w3-third' to='/support'>Eventura Support</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeView;
