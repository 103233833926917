import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import firebase from '../../Firebase';

import SpeakerAdd from './SpeakerAdd';
import SpeakerEdit from './SpeakerEdit';

import logo from '../../logo-white.png';
import menu from '../../images/ic_menu.png';
import close from '../../images/ic_close.png';
import add from '../../images/ic_add.png';

class SpeakerMain extends Component {
  constructor(props) {
    super(props);
    this.ref = firebase.firestore().collection('events');
    this.unsubscribe = null;
    this.state = {
      mainView: true, addView: false, editView: false, deleteView: false,
      allSpeakers: [],
      speakersView: '',
      selectedSpeaker: '',
      speakerID:'', first:'', last:'', company:'', title:'', phone:'', email:'', url:'', bio:'',
      deleteStyle: {display:'none'},
    }
    this.goMain = this.goMain.bind(this);
    this.goAdd = this.goAdd.bind(this);
    this.goEdit = this.goEdit.bind(this);
    this.goDelete = this.goDelete.bind(this);
    this.closeDelete = this.closeDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
  }

  getSpeakers = () => {
    const {eventID} = this.props;
    const {goEdit, goDelete} = this;
    var newSpeakers = [];
    var speakerView = '';
    var query = this.ref.doc(eventID).collection('speaker').where('eventID', '==', eventID);
    query.orderBy('last').get().then((snapshot) => {
      snapshot.forEach((doc) => {
        const { speakerID, eventID, eventName, first, last, company, title, phone, email, url, bio } = doc.data();
        newSpeakers.push({
          speakerID, eventID, eventName, first, last, company, title, phone, email, url, bio
        });
      });
    });
    setTimeout(() => {
      if (newSpeakers.length > 0) {
        this.setState({allSpeakers:newSpeakers});
        const {allSpeakers} = this.state;
        speakerView = allSpeakers.map(speaker => (
          <div className='w3-container w3-margin' key={speaker.speakerID}>
            <div className='w3-center w3-margin-bottom'><h4>Speaker: {speaker.first} {speaker.last}</h4></div>
            <div className='w3-row-padding w3-margin-top'>
              <div className='w3-threequarter'><div className='w3-left-align w3-margin-top'>Title: {speaker.title}</div></div>
              <div className='w3-quarter'>
                <button className='w3-btn w3-block w3-round-large w3-grey w3-text-white w3-margin-bottom' onClick={goEdit} value={speaker.speakerID} >Edit</button>
                <button className='w3-btn w3-block w3-round-large w3-red w3-text-white' onClick={goDelete} value={speaker.speakerID}>Delete</button>
              </div>
            </div>
          </div>
        ))
        this.setState({speakersView:speakerView});
      } else {
        speakerView = <div className='w3-container w3-margin'><p>Click the "+" above to add your first Speaker</p></div>
        this.setState({speakersView:speakerView});
      }
    }, 500)
  }

  componentDidMount() {
    this.unsubscribe = this.getSpeakers();
  }

  componentWillUnmount() {
    this.unsubscribe = null;
  }

  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  handleSubmit(e) {
    e.preventDefault();
    const {eventID} = this.props;
    var data = {
      eventID: this.props.eventID,
      eventName: this.props.eventName,
      first: this.state.first,
      last: this.state.last,
      title: this.state.title,
      company: this.state.company,
      phone: this.state.phone,
      email: this.state.email,
      url: this.state.url,
      bio: this.state.bio,
    }
    setTimeout(() => {
      var speakID;
      this.ref.doc(eventID).collection('speaker').add(data).then((docRef) => {
        console.log('speaker saved');
        speakID = docRef.id;
        this.setState({speakerID: speakID});
      }).catch((error) => {
        console.error('error adding speaker: ',error);
      }).then(() => {
        this.ref.doc(eventID).collection('speaker').doc(speakID).update({
          speakerID: speakID
        });
        this.setState({speakerID:speakID});
      })
      this.unsubscribe = null;
      this.goMain(e);
    }, 500)
  }

  handleEditChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  handleEditSubmit(e) {
    e.preventDefault();
    const {speakerID} = this.state;
    const {eventID} = this.props;
    var data = {
      eventID: this.props.eventID,
      eventName: this.props.eventName,
      first: this.state.first,
      last: this.state.last,
      title: this.state.title,
      company: this.state.company,
      phone: this.state.phone,
      email: this.state.email,
      url: this.state.url,
      bio: this.state.bio,
    }
    setTimeout(() => {
      this.ref.doc(eventID).collection('speaker').doc(speakerID).update(data);
    }, 100)
    this.unsubscribe = null;
    this.goMain(e);
  }

  handleDelete(e) {
    e.preventDefault();
    const {speakerID} = this.state;
    const {eventID} = this.props;
    this.ref.doc(eventID).collection('speaker').doc(speakerID).delete().then(() => {
      console.log('speaker deleted');
      this.setState({deleteStyle: {display:'none'},});
    }).catch((error) => {
      console.error('error deleting speaker');
    })
    this.unsubscribe = null;
    this.goMain(e);
  }

  goMain(e) {
    e.preventDefault();
    this.unsubscribe = this.getSpeakers();
    setTimeout(() => {
      this.setState({
        mainView: true,
        addView: false,
        editView: false,
        deleteView: false,
      })
    }, 500)
  }

  goAdd(e) {
    e.preventDefault();
    this.setState({
      mainView: false,
      addView: true,
      editView: false,
      deleteView: false,
      first:'', last:'', company:'', title:'', phone:'', email:'', url:'', bio:'',
    })
  }

  goEdit(e) {
    e.preventDefault();
    const {allSpeakers} = this.state;
    const key = e.target.value;
    allSpeakers.forEach((doc) => {
      if (doc.speakerID === key) {
        this.setState({
          selectedSpeaker: doc,
          speakerID: doc.speakerID,
          first: doc.first,
          last: doc.last,
          title: doc.title,
          company: doc.company,
          phone: doc.phone,
          email: doc.email,
          url: doc.url,
          bio: doc.bio,
        })
      }
    })
    setTimeout(() => {
      this.setState({
        mainView: false,
        addView: false,
        editView: true,
        deleteView: false,
      })
    }, 500)
  }

  goDelete(e) {
    e.preventDefault();
    const {allSpeakers} = this.state;
    const key = e.target.value;
    allSpeakers.forEach((doc) => {
      if (doc.speakerID === key) {
        this.setState({
          selectedSpeaker: doc,
          speakerID: doc.speakerID,
          first: doc.first,
          last: doc.last,
          title: doc.title,
          company: doc.company,
          phone: doc.phone,
          email: doc.email,
          url: doc.url,
          bio: doc.bio,
        })
      }
    })
    this.setState({
      deleteView: true,
      deleteStyle: {display:'block'},
    })
  }

  closeDelete(e) {
    e.preventDefault();
    this.setState({deleteStyle: {display:'none'},});
  }

  render() {
    const {eventName, eStart, eEnd, selectedBG, goView, } = this.props;
    const {mainView, addView, editView, speakersView, deleteStyle, speakerName, description} = this.state;
    const {goAdd, closeDelete, handleDelete} = this;
    let view;
    if (mainView) {
      view = (
        <div>
          <div className='w3-top w3-border-bottom e-opacity'>
            <div className='w3-bar w3-display-container'>
              <div className='w3-bar-item w3-left'>
                <Link to='/' style={{cursor:'pointer'}}><img className='w3-left' src={logo} alt='Eventura logo' style={{height:75}} /></Link>
                <span className='w3-margin w3-left w3-xlarge w3-text-white'>EVENTURA</span>
              </div>
              <img src={menu} className='w3-bar-item w3-right w3-margin-top' alt='ic_menu' style={{height:40}} />
            </div>
          </div>
          <div style={{height:150}}></div>
          <div style={{width:'75%',margin:'auto'}}>
            <div className='w3-card w3-display-center w3-round-large w3-margin w3-border w3-text-white e-opacity w3-animate-zoom'>
              <header className='w3-display-container w3-center'>
                <div className='w3-display-topright w3-margin' onClick={goView}>
                  <button className='w3-button w3-circle w3-red'><img src={close} alt='close' style={{width:15,height:15}} /></button>
                </div>
                <div className='w3-container w3-center'><h2>{eventName} Speaker</h2></div>
              </header>
              <div className='w3-display-container'>
                <div className='w3-round-large'>
                  <img className='w3-opacity-min' src={selectedBG} alt='background' style={{height:200,width:'100%'}} />
                </div>
                <div className='w3-display-topmiddle' style={{width:'90%'}}>
                  <h3 className='w3-center'>Event Dates: {eStart} - {eEnd}</h3>
                  <h3 className='w3-center'>{eventName} Speaker Info</h3>
                  <div className='w3-center'><button className='w3-button w3-circle w3-eventura'>
                    <img src={add} onClick={goAdd} alt='ic_add' style={{width:20,height:20}} />
                  </button></div>
                </div>
                <div className='w3-modal e-opacity' style={deleteStyle}>
                  <div className='w3-modal-content w3-eventura w3-animate-zoom w3-card-4'>
                    <header className='w3-container w3-indigo'>
                      <span className='w3-button w3-xlarge w3-display-topright' onClick={closeDelete}>&times;</span>
                      <h3>Are you sure you want to delete {speakerName}?</h3>
                    </header>
                    <div className='w3-container' style={{height:200}}>
                      <div className='w3-display-middle'><p>{description}</p></div>
                    </div>
                    <footer className='w3-container w3-row'>
                      <div className='w3-margin'>
                        <div className='w3-half w3-padding'><button className='w3-button w3-block w3-round-large w3-grey w3-text-white' onClick={closeDelete}>No, go back!</button></div>
                        <div className='w3-half w3-padding'><button className='w3-button w3-block w3-round-large w3-red w3-text-white' onClick={handleDelete}>Yes, delete!</button></div>
                      </div>
                    </footer>
                  </div>
                </div>
                <div className='w3-card e-opacity w3-container w3-margin w3-center' >
                {speakersView}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (addView) {
      view = (
        <div>
          <SpeakerAdd
            goMain={this.goMain} eventName={this.props.eventName} handleChange={this.handleChange} handleSubmit={this.handleSubmit}
            first={this.state.first} last={this.state.last} title={this.state.title} company={this.state.company}
            phone={this.state.phone} email={this.state.email} url={this.state.url} bio={this.state.bio}
          />
        </div>
      )
    } else if (editView) {
      view = (
        <div>
          <SpeakerEdit
            goMain={this.goMain} eventName={this.props.eventName} handleEditChange={this.handleEditChange} handleEditSubmit={this.handleEditSubmit}
            selectedSpeaker={this.state.selectedSpeaker} first={this.state.first} last={this.state.last} title={this.state.title} company={this.state.company}
            phone={this.state.phone} email={this.state.email} url={this.state.url} bio={this.state.bio}
          />
        </div>
      )
    }

    return (
      view
    );
  }
}

export default SpeakerMain;
