import React, { Component } from 'react';
import { auth } from '../Firebase/firebase.js'

import LoginView from './LoginView';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetStyle: {display:'none'},
      resetEmail: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.goReset = this.goReset.bind(this);
    this.closeReset = this.closeReset.bind(this);
  }

  onSubmit = async event => {
    event.preventDefault();
    const { email, password } = event.target.elements;
    try {
      await
      auth.signInWithEmailAndPassword(email.value, password.value);
      this.props.history.push('/');
    } catch (error) {
      alert(error);
    }
    this.props.history.push('/');
  }

  handleChange(e) {
    const value = e.target.value;
    this.setState({resetEmail: value});
  }

  handleReset(e) {
    e.preventDefault();
    const { resetEmail } = this.state;
    auth.sendPasswordResetEmail(resetEmail).then(() => {
      alert('A password email was sent to the provided email address.');
    }).catch((error) => {
      alert(error);
    });
    this.setState({
      resetStyle: {display:'none'},
    })
  }

  goReset(e) {
    e.preventDefault();
    this.setState({
      resetStyle: {display:'block'},
    })
  }

  closeReset(e) {
    e.preventDefault();
    this.setState({
      resetStyle: {display:'none'},
    })
  }

  render() {
    return (
      <LoginView onSubmit={this.onSubmit} handleReset={this.handleReset} goReset={this.goReset} closeReset={this.closeReset}
      handleChange={this.handleChange} resetStyle={this.state.resetStyle} resetEmail={this.state.resetEmail} />
    );
  }
}

export default LoginContainer;
