import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const config = {
  apiKey: "AIzaSyCmxKf3eam9u1ckEcrYe9q9Yq_KMEQZYy0",
  authDomain: "eventura-13bcc.firebaseapp.com",
  databaseURL: "https://eventura-13bcc.firebaseio.com",
  projectId: "eventura-13bcc",
  storageBucket: "eventura-13bcc.appspot.com",
  messagingSenderId: "36919199790"
};

firebase.initializeApp(config);
firebase.firestore();

export default firebase;
export const auth = firebase.auth();
export const store = firebase.storage();
export const blank = 'https://firebasestorage.googleapis.com/v0/b/eventura-13bcc.appspot.com/o/images%2Fprofile%2Fblank%2Fblank.png?alt=media&token=30d29767-edcc-44b6-b6ad-057874376fee';
