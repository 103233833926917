import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo-white.png';
import person from '../images/ic_person.png';
import email from '../images/ic_email.png';
import lock from '../images/ic_lock.png';

const SignUpView = ({ onChange, onSubmit }) => {

  return (
    <div className='w3-animate-bottom w3-container w3-center w3-text-white'>
      <div className='w3-display-container w3-margin' >
        <div className='w3-display-center'>
          <Link to='/home' style={{cursor:'pointer'}}><img src={logo} alt='Eventura Logo' style={{height: 200}} /></Link>
        </div>
      </div>
      <div style={{width:'75%',margin:'auto'}}>
        <div className='w3-card w3-border w3-display-center w3-round-large w3-padding w3-margin e-opacity'>
          <header className='w3-container'>
            <h2>Sign Up</h2>
          </header>
          <form className='w3-container w3-margin' onSubmit={onSubmit} >
            <div className='w3-row w3-margin' >
              <div className='w3-col w3-hide-small' style={{width:50}}><img src={person} alt='ic_person' style={{width:20}}/></div>
              <div className='w3-rest'><input className='w3-input w3-round-large' name='first' type='text' onChange={onChange} required placeholder='First Name' /></div>
            </div>
            <div className='w3-row w3-margin' >
              <div className='w3-col w3-hide-small' style={{width:50}}><img src={person} alt='ic_person' style={{width:20}} /></div>
              <div className='w3-rest'><input className='w3-input w3-round-large' name='last' type='text' onChange={onChange} required placeholder='Last Name' /></div>
            </div>
            <div className='w3-row w3-margin'>
              <div className='w3-col w3-hide-small' style={{width:50}}><img src={email} alt='ic_email' style={{width:20}} /></div>
              <div className='w3-rest'><input className='w3-input w3-round-large' name='email' type='text' onChange={onChange} placeholder='Email' /></div>
            </div>
            <div className='w3-row w3-margin' >
              <div className='w3-col w3-hide-small' style={{width:50}}><img src={lock} alt='ic_lock' style={{width:20}} /></div>
              <div className='w3-rest'><input className='w3-input w3-round-large' name='password' type='password' onChange={onChange} placeholder='Password' /></div>
            </div>
            <button className='w3-button w3-round-large w3-red' type="submit">Sign In</button>
          </form>
          <Link className='w3-margin' to='/login'>I have an account</Link>
        </div>
      </div>
    </div>
  );
};

export default SignUpView;
