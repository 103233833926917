import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from './logo-white.png';

class Support extends Component {

  render() {
    return (
      <div className='w3-animate-zoom w3-display-container w3-margin w3-center'>
        <Link to='/home' style={{cursor:'pointer'}}><img src={logo} alt='Eventura Logo' style={{height: 200}} /></Link>
        <div style={{width:'90%', margin:'auto'}}>
          <div className='w3-card w3-border w3-display-center w3-round-large w3-padding w3-margin w3-text-white e-opacity'>
            <h2>Eventura Support</h2>
            <p>
              Eventura is currently in Testing Phase, therefore many of the features are in the process of being added or enhanced.
              We appreciate your business, and are driven to ensure that you are satisfied with your experience using our service.
            </p>
            <b>
              Web Portal Support
            </b>
            <p>
              If you experience any issues with any aspect of the web portal, please feel free to contact the support team at info@rtswebsolutions.com.
            </p>
            <b>
              Mobile App Support
            </b>
            <p>
              If you experience any issues with any aspect of the web portal, please feel free to contact the support team at info@rtswebsolutions.com.
            </p>
            <b>
              Event Support
            </b>
            <p>
              If you experience any issues with anything related to an event, please feel free to contact the event organizer.
              Each event's Point of Contact information is provided through the main screen of the event by selecting the "Contact Event Organizer" button.
            </p>
            <p>
              This document was last updated on June 10, 2019
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Support;
