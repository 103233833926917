import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from './logo-white.png';

class Terms extends Component {

  render() {
    return (
      <div className='w3-animate-zoom w3-display-container w3-margin w3-center'>
        <Link to='/home' style={{cursor:'pointer'}}><img src={logo} alt='Eventura Logo' style={{height: 200}} /></Link>
        <div style={{width:'90%', margin:'auto'}}>
          <div className='w3-card w3-border w3-display-center w3-round-large w3-padding w3-margin w3-text-white e-opacity'>
            <h2>Eventura Terms and Conditions</h2>
            <p>
              Please read these terms and conditions ("terms", "terms and conditions") carefully before using Eventura website (the "service") operated by RTS Web Solutions ("us", “we", "our").
            </p>
            <b>
              Conditions of Use
            </b>
            <p>
              We will provide their services to you, which are subject to the conditions stated below in this document. Every time you visit this website, use its services or make a purchase, you accept the following conditions. This is why we urge you to read them carefully.
            </p>
            <b>
              Privacy Policy
            </b>
            <p>
              Before you continue using our website we advise you to read our privacy policy regarding our user data collection. It will help you better understand our practices.
            </p>
            <b>
              Copyright
            </b>
            <p>
              Content published on this website (digital downloads, images, texts, graphics, logos) is the property of Eventura and/or its content creators and protected by international copyright laws. The entire compilation of the content found on this website is the exclusive property of Eventura, with copyright authorship for this compilation by RTS Web Solutions.
            </p>
            <b>
              Communications
            </b>
            <p>
              The entire communication with us is electronic. Every time you send us an email or visit our website, you are going to be communicating with us. You hereby consent to receive communications from us. We may communicate with you by posting news and notices on our website and by sending you emails. You also agree that all notices, disclosures, agreements and other communications we provide to you electronically meet the legal requirements that such communications be in writing.
            </p>
            <b>
              Applicable Law
            </b>
            <p>
              By visiting this website, you agree that the laws of the United States, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between Eventura and you, or its business partners and associates.
            </p>
            <b>
              Disputes
            </b>
            <p>
              Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by state or federal court United States and you consent to exclusive jurisdiction and venue of such courts.
            </p>
            <b>
              Comments, Reviews, and Emails
            </b>
            <p>
              Visitors may post content as long as it is not obscene, illegal, defamatory, threatening, infringing of intellectual property rights, invasive of privacy or injurious in any other way to third parties. Content has to be free of software viruses, political campaign, and commercial solicitation.
            </p>
            <p>
              We reserve all rights (but not the obligation) to remove and/or edit such content. When you post your content, you grant Eventura non-exclusive, royalty-free and irrevocable right to use, reproduce, publish, modify such content throughout the world in any media.
            </p>
            <b>
              License and Site Access
            </b>
            <p>
              We grant you a limited license to access and make personal use of this website. You are not allowed to download or modify it. This may be done only with written consent from us.
            </p>
            <b>
              User Account
            </b>
            <p>
              If you are an owner of an account on this website, you are solely responsible for maintaining the confidentiality of your private user details (username and password). You are responsible for all activities that occur under your account or password.
            </p>
            <p>
              We reserve all rights to terminate accounts, edit or remove content and cancel orders in their sole discretion.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Terms;
