import React, { Component } from 'react';
import firebase from '../Firebase';
import {store} from '../Firebase/firebase.js';
import axios from 'axios';
import moment from 'moment';

import EventsView from './EventsView';
import ViewEvent from './ViewEvent';
import EditEvent from './EditEvent';
import DiningMain from './Sections/DiningMain';
import ScheduleMain from './Sections/ScheduleMain';
import SpeakerMain from './Sections/SpeakerMain';
import SponsorMain from './Sections/SponsorMain';
import TravelMain from './Sections/TravelMain';
import CustomMain from './Sections/CustomMain';

import draft from '../images/switchDraft2.png';
import live from '../images/switchPublish.png';

const sgMail = require('@sendgrid/mail');
const SENDGRID_API_KEY = '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';
sgMail.setApiKey(SENDGRID_API_KEY);
// sgMail.setApiKey(SENDGRID_API_KEY);

class EventsContainer extends Component {
  constructor(props) {
    super(props);
    this.ref = firebase.firestore().collection('events');
    this.unsubscribe = null;
    this.state = {
      business1:'', business2:'', family1:'', family2:'', religious1:'', religious2:'', social1:'', social2:'', wedding1:'', wedding2:'',
      eventsView: true, viewEvent: false, editEvent:false, diningView:false, scheduleView: false, speakerView:false, sponsorView:false, travelView:false, customView: false,
      allEvents: [],
      selectedEvent: '', startChange:false, endChange:false, bgChange:false,
      publish: false, toggle: draft, bgChoice: 'custom', bgMessage:'Choose your own background image',
      sectionOptions: [{text:'Dining',value:'Dining'},{text:'Speaker',value:'Speaker'},{text:'Sponsor',value:'Sponsor'},{text:'Travel',value:'Travel'}],
      eventID: '', eventCode: '', name: '', mode: '', type: '', limit: '',
      start: moment(), end: moment(), email: '', phone: '',
      description: '', city: '', state: '', zip: '', background: '',
      section1: 'Schedule', section2: '', section3: '', section4: '', custom: '', customLabel:'',
      name1: '', phone1: '', email1: '', name2: '', phone2: '', email2: '', defaultBG1: '', defaultBG2: '', selectedBG: '',
      infoStyle: {display:'block'}, detailsStyle: {display:'none'}, sectionsStyle: {display:'none'}, backgroundStyle: {display:'none'},
      infoHeadStyle: {background:'#0f0e85',color:'white',cursor:'pointer'}, detailsHeadStyle: {background:'#3e528d',color:'white',cursor:'pointer'}, sectionsHeadStyle: {background:'#3e528d',color:'white',cursor:'pointer'}, backgroundHeadStyle: {background:'#3e528d',color:'white',cursor:'pointer'},
      infoViewStyle: {display:'block'}, dashboardStyle: {display:'none'},
      infoViewHead: {background:'#0f0e85', cursor:'pointer'}, dashboardHead: {background:'#3e528d', cursor:'pointer'},
      isSection2: false, isSection3: false, isSection4: false, isCustom: false,
      defaultBgStyle: {display:'block'}, customBgStyle: {display:'none'},
    }
    this.goInfo = this.goInfo.bind(this);
    this.goDetails = this.goDetails.bind(this);
    this.goSections = this.goSections.bind(this);
    this.goBackground = this.goBackground.bind(this);
    this.goView = this.goView.bind(this);
    this.goEvents = this.goEvents.bind(this);
    this.goEdit = this.goEdit.bind(this);
    this.goInfoView = this.goInfoView.bind(this);
    this.goDashboard = this.goDashboard.bind(this);
    this.setPublish = this.setPublish.bind(this);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
    this.handleSection = this.handleSection.bind(this);
    this.handleSectionChange = this.handleSectionChange.bind(this);
    this.handleBackground = this.handleBackground.bind(this);
    this.handleBgSelect = this.handleBgSelect.bind(this);
    this.handleBgChoice = this.handleBgChoice.bind(this);
    this.getBackgrounds = this.getBackgrounds.bind(this);
    this.sendMail = this.sendMail.bind(this);
  }

  getEvents = () => {
    const { userID } = this.props;
    const newEvents = [];
    var query = this.ref.where('creatorID', '==', userID);
    query.get().then((snapshot) => {
      snapshot.forEach((doc) => {
        const { creatorID, eventCode, name, mode, type, limit, start, end, description, city, state, zip, name1, email1, phone1, name2, email2, phone2, section1, section2, section3, section4, custom, background, publish } = doc.data();
        newEvents.push({
          key: doc.id, doc, creatorID, eventCode, name, mode, type, limit, start, end, description, city, state, zip, name1, email1, phone1, name2, email2, phone2, section1, section2, section3, section4, custom, background, publish
        });
      });
    });
    setTimeout(() => {
      this.setState({allEvents:newEvents});
    }, 900)
  }

  componentDidMount() {
    this.unsubscribe = this.getEvents();
    this.getBackgrounds();
  }

  componentWillUnmount() {
    this.unsubscribe = null;
  }

  getBackgrounds() {
    var business1Url = store.ref('images/background/business1.png');
    var business2Url = store.ref('images/background/business2.png');
    var family1Url = store.ref('images/background/family1.png');
    var family2Url = store.ref('images/background/family2.png');
    var religious1Url = store.ref('images/background/religious1.png');
    var religious2Url = store.ref('images/background/religious2.png');
    var social1Url = store.ref('images/background/social1.png');
    var social2Url = store.ref('images/background/social2.png');
    var wedding1Url = store.ref('images/background/wedding1.png');
    var wedding2Url = store.ref('images/background/wedding2.png');
    business1Url.getDownloadURL().then((url) => {
      this.setState({business1:url});
    })
    business2Url.getDownloadURL().then((url) => {
      this.setState({business2:url});
    })
    family1Url.getDownloadURL().then((url) => {
      this.setState({family1:url});
    })
    family2Url.getDownloadURL().then((url) => {
      this.setState({family2:url});
    })
    religious1Url.getDownloadURL().then((url) => {
      this.setState({religious1:url});
    })
    religious2Url.getDownloadURL().then((url) => {
      this.setState({religious2:url});
    })
    social1Url.getDownloadURL().then((url) => {
      this.setState({social1:url});
    })
    social2Url.getDownloadURL().then((url) => {
      this.setState({social2:url});
    })
    wedding1Url.getDownloadURL().then((url) => {
      this.setState({wedding1:url});
    })
    wedding2Url.getDownloadURL().then((url) => {
      this.setState({wedding2:url});
    })
  }

  handleEditChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  handleStartChange(start) {
    this.setState({start, startChange:true});
  }

  handleEndChange(end) {
    this.setState({end, endChange:true});
  }

  handleSection(e) {
    e.preventDefault();
    const section = e.target.value;
    if(section === 'Dining') {this.setState({diningView:true, scheduleView: false, speakerView:false, sponsorView:false, travelView:false, customView: false, viewEvent:false, editEvent:false})}
    else if(section === 'Schedule') {this.setState({diningView:false, scheduleView: true, speakerView:false, sponsorView:false, travelView:false, customView: false, viewEvent:false, editEvent:false})}
    else if(section === 'Speaker') {this.setState({diningView:false, scheduleView: false, speakerView:true, sponsorView:false, travelView:false, customView: false, viewEvent:false, editEvent:false})}
    else if(section === 'Sponsor') {this.setState({diningView:false, scheduleView: false, speakerView:false, sponsorView:true, travelView:false, customView: false, viewEvent:false, editEvent:false})}
    else if(section === 'Travel') {this.setState({diningView:false, scheduleView: false, speakerView:false, sponsorView:false, travelView:true, customView: false, viewEvent:false, editEvent:false})}
    else{this.setState({diningView:false, scheduleView: false, speakerView:false, sponsorView:false, travelView:false, customView: true, viewEvent:false, editEvent:false})}
  }

  handleSectionChange(e) {
    const {section2, section3, section4} = this.state;
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'section2') {
      if ((value === section3) || (value === section4)) {
        if (value === '') {
          this.setState({[name]: value});
        } else {
          console.log('section taken!');
        }
      } else {
        this.setState({[name]: value});
      }
    } else if (name === 'section3') {
      if ((value === section2) || (value === section4)) {
        if (value === '') {
          this.setState({[name]: value});
        } else {
          console.log('section taken!');
        }
      } else {
        this.setState({[name]: value});
      }
    } else if (name === 'section4') {
      if ((value === section2) || (value === section3)) {
        if (value === '') {
          this.setState({[name]: value});
        } else {
          console.log('section taken!');
        }
      } else {
        this.setState({[name]: value});
      }
    } else if (name === 'custom') {
      if ((value === section2) || (value === section3) || (value === section4)) {
        if (value === '') {
          this.setState({[name]: value});
        } else {
          console.log('section taken!');
        }
      } else {
        this.setState({[name]: value});
      }
    }
  }

  handleBgChoice(e) {
    e.preventDefault();
    const {bgChoice} = this.state;
    if (bgChoice === 'default') {
      console.log('bgChoice: ',bgChoice);
      this.setState({
        bgChoice: 'custom',
        bgMessage: 'Choose your own background image',
        defaultBgStyle: {display:'block'},
        customBgStyle: {display:'none'}
      })
    } else {
      console.log('bgChoice: ',bgChoice);
      this.setState({
        bgChoice: 'default',
        bgMessage: 'Choose default background image',
        defaultBgStyle: {display:'none'},
        customBgStyle: {display:'block'}
      })
    }
  }

  handleBgSelect(e) {
    const {defaultBG1, defaultBG2} = this.state;
    const name = e.target.name;
    let value;
    if (name ==='BG1') {
      this.setState({bg1Style:{background:'#3e528d'},bg2Style:{background:'none'}});
      value = defaultBG1
    }
    else if (name ==='BG2') {
      this.setState({bg1Style:{background:'none'},bg2Style:{background:'#3e528d'}});
      value = defaultBG2
    }
    this.setState({selectedBG:value,bgChange:true});
  }

  handleBackground(e) {
    let photo = e.target.files[0];
    this.setState({selectedBG:photo,bgChange:true});
  }

  handleEditSubmit(e) {
    e.preventDefault();
    const {eventID, startChange, endChange, start, end, bgChange, bgChoice, selectedBG} = this.state;
    var newStart, newEnd;
    if (startChange) {
      newStart = start.format('MMMM DD, YYYY');
    } else {
      newStart = start;
    }
    if (endChange) {
      newEnd = end.format('MMMM DD, YYYY');
    } else {
      newEnd = end;
    }
    var data = {
      name: this.state.name, mode: this.state.mode, type: this.state.type, limit: this.state.limit,
      start: newStart, end: newEnd, description: this.state.description, city: this.state.city, state: this.state.state, zip: this.state.zip, publish:this.state.publish,
      name1: this.state.name1, email1:this.state.email1, phone1:this.state.phone1, name2:this.state.name2, email2:this.state.email2, phone2:this.state.phone2,
      section1: 'Schedule', section2: this.state.section2, section3: this.state.section3, section4: this.state.section4, custom: this.state.custom,
    }
    setTimeout(() => {
      this.ref.doc(eventID).update(data).then(() => {
        var uploadTask;
        if (bgChange) {
          if (bgChoice === 'default') {
            uploadTask = store.ref('images/background').child(eventID).child('/background.jpg').put(selectedBG);
            uploadTask.on('state_changed', (snapshot) => {
              console.log('state changed...');
            }, (error) => {
              console.log(error);
            }, () => {
              uploadTask.snapshot.ref.getDownloadURL().then((url) => {
                this.ref.doc(eventID).update({background: url});
                this.setState({background: url});
              })
            })
          } else if (bgChoice === 'custom') {
            this.ref.doc(eventID).update({background: selectedBG});
            this.setState({background: selectedBG});
          }
        }
      })
    }, 100)
    this.setState({startChange:false, endChange:false, bgChange:false});
    this.unsubscribe = null;
    this.goView(e);
  }

  setPublish(e) {
    e.preventDefault();
    const {publish} = this.state;
    if (publish) {
      this.setState({
        publish: false,
        toggle: draft,
      });
    } else {
      this.setState({
        publish: true,
        toggle: live,
      });
    }
  }

  sendMail(e) {
    e.preventDefault();
    // const { email1 } = this.state;
    console.log('send mail pressed');
    const msg = {
      email: 'robertrawlins@hotmail.com',
      message: 'Eventura Test Email',
    };
    axios.post('https://us-central1-eventura-13bcc.cloudfunctions.net/submit', msg).then(() => {
      console.log('message sent!');
    }).catch(error => {
      console.log(error.toString());
    });
  }

  goEvents(e) {
    e.preventDefault();
    this.unsubscribe = this.getEvents();
    this.setState({
      eventsView: true,
      viewEvent: false,
      editEvent:false,
      diningView: false,
      scheduleView: false,
      speakerView: false,
      sponsorView: false,
      travelView: false,
      customView: false,
    })
  }

  goView(e) {
    e.preventDefault();
    this.unsubscribe = this.getEvents();
    const {allEvents} = this.state;
    const key = e.target.value;
    allEvents.forEach((doc) => {
      if (doc.key === key) {
        if (doc.section2 !== '') {this.setState({isSection2:true});} else {this.setState({isSection2:false});}
        if (doc.section3 !== '') {this.setState({isSection3:true});} else {this.setState({isSection3:false});}
        if (doc.section4 !== '') {this.setState({isSection4:true});} else {this.setState({isSection4:false});}
        if (doc.custom !== '') {this.setState({isCustom:true, customLabel:doc.custom});} else {this.setState({isCustom:false});}
        this.setState({
          key:doc.key, creatorID:doc.creatorID, eventID:doc.key, eventCode:doc.eventCode,
          name:doc.name, mode:doc.mode, type:doc.type, limit:doc.limit, start:doc.start, end:doc.end, description:doc.description,
          city:doc.city, state:doc.state, zip:doc.zip, name1:doc.name1, email1:doc.email1, phone1:doc.phone1, name2:doc.name2, email2:doc.email2, phone2:doc.phone2,
          section1:doc.section1, section2:doc.section2, section3:doc.section3, section4:doc.section4, custom:doc.custom, background:doc.background, publish:doc.publish,
        });
        }
    })
    this.setState({
      viewEvent: true,
      eventsView: false,
      infoStyle: {display:'block'},
      detailsStyle: {display:'none'},
      sectionsStyle: {display:'none'},
      backgroundStyle: {display:'none'},
      infoHeadStyle: {background:'#0f0e85'},
      detailsHeadStyle: {background:'#3e528d',cursor:'pointer'},
      sectionsHeadStyle: {background:'#3e528d',cursor:'pointer'},
      backgroundHeadStyle: {background:'#3e528d',cursor:'pointer'},
    });
  }

  goInfoView(e) {
    e.preventDefault();
    this.setState({
      infoViewStyle: {display:'block'},
      dashboardStyle: {display:'none'},
      infoViewHead: {background:'#0f0e85',cursor:'pointer'},
      dashboardHead: {background:'#3e528d',cursor:'pointer'},
    })
  }

  goDashboard(e) {
    e.preventDefault();
    this.setState({
      infoViewStyle: {display:'none'},
      dashboardStyle: {display:'block'},
      infoViewHead: {background:'#3e528d',cursor:'pointer'},
      dashboardHead: {background:'#0f0e85',cursor:'pointer'},
    })
  }

  goEdit(e) {
    e.preventDefault();
    this.setState({
      eventsView: false,
      viewEvent: false,
      editEvent:true,
      diningView: false,
      scheduleView: false,
      speakerView: false,
      sponsorView: false,
      travelView: false,
      customView: false,
      infoHeadStyle: {background:'#0f0e85'},
    });
  }

  goInfo(e) {
    e.preventDefault();
    this.setState({
      infoStyle: {display:'block'},
      detailsStyle: {display:'none'},
      sectionsStyle: {display:'none'},
      backgroundStyle: {display:'none'},
      infoHeadStyle: {background:'#0f0e85',cursor:'pointer'},
      detailsHeadStyle: {background:'#3e528d',cursor:'pointer'},
      sectionsHeadStyle: {background:'#3e528d',cursor:'pointer'},
      backgroundHeadStyle: {background:'#3e528d',cursor:'pointer'},
    });
  }

  goDetails(e) {
    e.preventDefault();
    this.setState({
      infoStyle: {display:'none'},
      detailsStyle: {display:'block'},
      sectionsStyle: {display:'none'},
      backgroundStyle: {display:'none'},
      infoHeadStyle: {background:'#3e528d',cursor:'pointer'},
      detailsHeadStyle: {background:'#0f0e85',cursor:'pointer'},
      sectionsHeadStyle: {background:'#3e528d',cursor:'pointer'},
      backgroundHeadStyle: {background:'#3e528d',cursor:'pointer'},
    });
  }

  goSections(e) {
    e.preventDefault();
    this.setState({
      infoStyle: {display:'none'},
      detailsStyle: {display:'none'},
      sectionsStyle: {display:'block'},
      backgroundStyle: {display:'none'},
      infoHeadStyle: {background:'#3e528d',cursor:'pointer'},
      detailsHeadStyle: {background:'#3e528d',cursor:'pointer'},
      sectionsHeadStyle: {background:'#0f0e85',cursor:'pointer'},
      backgroundHeadStyle: {background:'#3e528d',cursor:'pointer'},
    });
  }

  goBackground(e) {
    e.preventDefault();
    const {type, business1, business2, family1, family2, religious1, religious2, social1, social2, wedding1, wedding2} = this.state;
    if (type === 'Business') {this.setState({defaultBG1:business1, defaultBG2:business2});}
    else if (type === 'Family') {this.setState({defaultBG1:family1, defaultBG2:family2});}
    else if (type === 'Religious') {this.setState({defaultBG1:religious1, defaultBG2:religious2});}
    else if (type === 'Social') {this.setState({defaultBG1:social1, defaultBG2:social2});}
    else if (type === 'Wedding') {this.setState({defaultBG1:wedding1, defaultBG2:wedding2});}
    this.setState({
      infoStyle: {display:'none'},
      detailsStyle: {display:'none'},
      sectionsStyle: {display:'none'},
      backgroundStyle: {display:'block'},
      infoHeadStyle: {background:'#3e528d',cursor:'pointer'},
      detailsHeadStyle: {background:'#3e528d',cursor:'pointer'},
      sectionsHeadStyle: {background:'#3e528d',cursor:'pointer'},
      backgroundHeadStyle: {background:'#0f0e85',cursor:'pointer'},
    });
  }

  render() {
    const {eventsView, viewEvent, editEvent, diningView, scheduleView, speakerView, sponsorView, travelView, customView} = this.state;
    let view;
    if (viewEvent) {
      view = (
        <div>
          <ViewEvent
            selectedEvent={this.state.selectedEvent}
            goEvents={this.goEvents} goEdit={this.goEdit} goInfoView={this.goInfoView} goDashboard={this.goDashboard} handleSection={this.handleSection}
            setPublish={this.setPublish} toggle={this.state.toggle} sendMail={this.sendMail}
            infoViewStyle={this.state.infoViewStyle} dashboardStyle={this.state.dashboardStyle}
            infoViewHead={this.state.infoViewHead} dashboardHead={this.state.dashboardHead}
            eventID={this.state.eventID} eventCode={this.state.eventCode} name={this.state.name} mode={this.state.mode} type={this.state.type} limit={this.state.limit}
            start={this.state.start} end={this.state.end} description={this.state.description}
            city={this.state.city} state={this.state.state} zip={this.state.zip} background={this.state.background} publish={this.state.publish}
            name1={this.state.name1} email1={this.state.email1} phone1={this.state.phone1}
            name2={this.state.name2} email2={this.state.email2} phone2={this.state.phone2}
            section1={this.state.section1} section2={this.state.section2} section3={this.state.section3} section4={this.state.section4} custom={this.state.custom}
            isSection2={this.state.isSection2} isSection3={this.state.isSection3} isSection4={this.state.isSection4} isCustom={this.state.isCustom}
          />
        </div>
      )
    } else if (editEvent) {
      view = (
        <div>
          <EditEvent
            selectedEvent={this.state.selectedEvent} sectionOptions={this.state.sectionOptions}
            handleEditChange={this.handleEditChange} handleStartChange={this.handleStartChange} handleEndChange={this.handleEndChange} handleBackground={this.handleBackground}
            goView={this.goView} handleSection={this.handleSection} handleEditSubmit={this.handleEditSubmit} handleSectionChange={this.handleSectionChange}
            goInfo={this.goInfo} goDetails={this.goDetails} goSections={this.goSections} goBackground={this.goBackground}
            setPublish={this.setPublish} toggle={this.state.toggle}
            handleBgSelect={this.handleBgSelect} handleBgChoice={this.handleBgChoice}
            infoStyle={this.state.infoStyle} detailsStyle={this.state.detailsStyle} sectionsStyle={this.state.sectionsStyle} backgroundStyle={this.state.backgroundStyle}
            infoHeadStyle={this.state.infoHeadStyle} detailsHeadStyle={this.state.detailsHeadStyle} sectionsHeadStyle={this.state.sectionsHeadStyle} backgroundHeadStyle={this.state.backgroundHeadStyle}
            infoViewStyle={this.state.infoViewStyle} dashboardStyle={this.state.dashboardStyle}
            infoViewHead={this.state.infoViewHead} dashboardHead={this.state.dashboardHead}
            eventID={this.state.eventID} eventCode={this.state.eventCode} name={this.state.name} mode={this.state.mode} type={this.state.type} limit={this.state.limit}
            start={this.state.start} end={this.state.end} description={this.state.description}
            city={this.state.city} state={this.state.state} zip={this.state.zip} background={this.state.background} publish={this.state.publish}
            name1={this.state.name1} email1={this.state.email1} phone1={this.state.phone1}
            name2={this.state.name2} email2={this.state.email2} phone2={this.state.phone2}
            section1={this.state.section1} section2={this.state.section2} section3={this.state.section3} section4={this.state.section4} custom={this.state.custom}
            isSection2={this.state.isSection2} isSection3={this.state.isSection3} isSection4={this.state.isSection4} isCustom={this.state.isCustom}
            defaultBG1={this.state.defaultBG1} defaultBG2={this.state.defaultBG2} bg1Style={this.state.bg1Style} bg2Style={this.state.bg2Style}
            defaultBgStyle={this.state.defaultBgStyle} customBgStyle={this.state.customBgStyle} bgMessage={this.state.bgMessage}
          />
        </div>
      )
    } else if (diningView) {
      view = (
        <div>
          <DiningMain
            eventID={this.state.eventID} eventName={this.state.name} eStart={this.state.start} eEnd={this.state.end}
            goView={this.goView} selectedBG={this.state.background}
          />
        </div>
      )
    } else if (scheduleView) {
      view = (
        <div>
          <ScheduleMain
            eventID={this.state.eventID} eventName={this.state.name} eStart={this.state.start} eEnd={this.state.end}
            goView={this.goView} selectedBG={this.state.background}
          />
        </div>
      )
    } else if (speakerView) {
      view = (
        <div>
          <SpeakerMain
            eventID={this.state.eventID} eventName={this.state.name} eStart={this.state.start} eEnd={this.state.end}
            goView={this.goView} selectedBG={this.state.background}
          />
        </div>
      )
    } else if (sponsorView) {
      view = (
        <div>
          <SponsorMain
            eventID={this.state.eventID} eventName={this.state.name} eStart={this.state.start} eEnd={this.state.end}
            goView={this.goView} selectedBG={this.state.background}
          />
        </div>
      )
    } else if (travelView) {
      view = (
        <div>
          <TravelMain
            eventID={this.state.eventID} eventName={this.state.name} eStart={this.state.start} eEnd={this.state.end}
            goView={this.goView} selectedBG={this.state.background}
          />
        </div>
      )
    } else if (customView) {
      view = (
        <div>
          <CustomMain
            eventID={this.state.eventID} eventName={this.state.name} eStart={this.state.start} eEnd={this.state.end} customLabel={this.state.customLabel}
            goView={this.goView} selectedBG={this.state.background}
          />
        </div>
      )
    } else if (eventsView) {
      view = (
        <div>
          <EventsView
            allEvents={this.state.allEvents} goView={this.goView}
            business1={this.state.business1} business2={this.state.business2} family1={this.state.family1} family2={this.state.family2} religious1={this.state.religious1}
            religious2={this.state.religious2} social1={this.state.social1} social2={this.state.social2} wedding1={this.state.wedding1} wedding2={this.state.wedding2}
          />
        </div>
      )
    }
    return (
      view
    );
  }
}

export default EventsContainer;
