import React from 'react';
import { Link } from 'react-router-dom';


import logo from '../logo-white.png';
import menu from '../images/ic_menu.png';
import add from '../images/ic_add.png';
import close from '../images/ic_close.png';

const EventsView = ({allEvents, goView, business1, business2, family1, family2, religious1, religious2, social1, social2, wedding1, wedding2}) => {

  return (
    <div>
      <div className='w3-top w3-border-bottom e-opacity'>
        <div className='w3-bar w3-display-container'>
          <div className='w3-bar-item w3-left'>
            <Link to='/' style={{cursor:'pointer'}}><img className='w3-left' src={logo} alt='Eventura logo' style={{height:75}} /></Link>
            <span className='w3-margin w3-left w3-xlarge w3-text-white'>EVENTURA</span>
          </div>
          <img src={menu} className='w3-bar-item w3-right w3-margin-top' alt='ic_menu' style={{height:40}} />
        </div>
      </div>
      <div style={{height:150}}></div>
      <div style={{width:'75%',margin:'auto'}}>
        <div className='w3-card w3-display-center w3-round-large w3-margin w3-border w3-text-white e-opacity'>
          <header className='w3-display-container w3-center'>
            <Link to='/' className='w3-display-topright w3-margin'>
              <button className='w3-button w3-circle w3-red'>
                <img src={close} alt='ic_close' style={{width:15,height:15}} />
              </button>
            </Link>
            <h2>Events</h2>
            <Link className='w3-margin' to='/createEvent'
              business1={business1} business2={business2} family1={family1} family2={family2} religious1={religious1}
              religious2={religious2} social1={social1} social2={social2} wedding1={wedding1} wedding2={wedding2}>
              <button className='w3-button w3-circle w3-eventura'>
                <img src={add} alt='ic_add' style={{height:20}} />
              </button>
            </Link>
          </header>
          <div className='w3-container w3-margin-top w3-margin-bottom'>
            <div className='w3-row'>
              {allEvents.sort((a,b) => a.timeStamp > b.timeStamp).map(item =>
                <div key={item.key} className='w3-button w3-hover-none w3-display-container' style={{height:200, cursor:'default'}} >
                  <img className='w3-black w3-opacity-min' src={item.background} style={{width:'100%',height:'100%'}} alt='background' />
                  <div className='w3-display-topmiddle w3-margin-top'>
                    <div className='w3-container'>
                      <div className='w3-center e-shadow'><h3>{item.name}</h3></div>
                      <div className='w3-center e-shadow'>Event Code: {item.eventCode}</div>
                      <div className='w3-center e-shadow'>Start Date: {item.start}</div>
                    </div>
                  </div>
                  <div className='w3-display-bottommiddle'><button className='w3-button w3-margin-bottom w3-round-large w3-eventura' value={item.key} onClick={goView}>Manage</button></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventsView;
