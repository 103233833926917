import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { auth } from './Firebase/firebase.js';

import Home from './Home';
import Privacy from './privacy';
import Terms from './terms';
import Support from './support';
import Login from './Login';
import SignUp from './SignUp';
import Main from './Main';
import Events from './Events';
import CreateEvent from './Events/Create';
import PrivateRoute from './privateRoute';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: null,
      email: '',
      userName: '',
      userID: '',
    }
    this.getUserData = this.getUserData.bind(this);
  }

  componentDidMount() {
    this.getUserData();
  }

  componentWillUnmount() {
    this.listener();
  }

  getUserData() {
    this.listener = auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          authenticated: true,
          currentUser: user,
          email: user.email,
          userID: user.uid,
          userName: user.displayName,
        })
      } else {
        this.setState({
          authenticated: false,
          currentUser: '',
          email: '',
          userID: '',
          userName: '',
        })
      }
    });
  }

  render() {
    return (
      <div>
        <Router>
          <div>
            <PrivateRoute exact path='/' component={Main} authenticated={this.state.authenticated} userID={this.state.userID} userName={this.state.userName} />
            <Route exact path='/home' component={Home} />
            <Route exact path='/privacy' component={Privacy} />
            <Route exact path='/terms' component={Terms} />
            <Route exact path='/support' component={Support} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/signup' component={SignUp} />
            <PrivateRoute exact path='/events' component={Events}
              authenticated={this.state.authenticated} userID={this.state.userID} userName={this.state.userName}
            />
            <PrivateRoute exact path='/createEvent' component={CreateEvent}
              authenticated={this.state.authenticated} userID={this.state.userID} userName={this.state.userName}
              business1={this.state.business1} business2={this.state.business2} family1={this.state.family1} family2={this.state.family2} religious1={this.state.religious1}
              religious2={this.state.religious2} social1={this.state.social1} social2={this.state.social2} wedding1={this.state.wedding1} wedding2={this.state.wedding2}
            />
          </div>
        </Router>
      </div>
    );
  }
};

export default App;
