import React from 'react';
import { Link } from 'react-router-dom';
import Datetime from 'react-datetime';
import '../../react-datetime.css';

import logo from '../../logo-white.png';
import menu from '../../images/ic_menu.png';
import close from '../../images/ic_close.png';

const ScheduleAdd = ({
  goMain, handleChange, handleSubmit, handleDateChange, handleStartChange, handleEndChange,
  eventName, activityName, host, date, start, end, description,
}) => {

  return (
    <div>
      <div className='w3-top w3-border-bottom e-opacity'>
        <div className='w3-bar w3-display-container'>
          <div className='w3-bar-item w3-left'>
            <Link to='/' style={{cursor:'pointer'}}><img className='w3-left' src={logo} alt='Eventura logo' style={{height:75}} /></Link>
            <span className='w3-margin w3-left w3-xlarge w3-text-white'>EVENTURA</span>
          </div>
          <img src={menu} className='w3-bar-item w3-right w3-margin-top' alt='ic_menu' style={{height:40}} />
        </div>
      </div>
      <div style={{height:150}}></div>
      <div style={{width:'75%',margin:'auto'}}>
        <div className='w3-card w3-display-center w3-round-large w3-margin w3-border w3-text-white e-opacity w3-animate-zoom'>
          <header className='w3-display-container w3-center'>
            <div className='w3-display-topright w3-margin'>
              <button className='w3-button w3-circle w3-red'><img src={close} onClick={goMain} alt='close' style={{width:15,height:15}} /></button>
            </div>
            <div className='w3-container w3-center'><h2>Add {eventName} Activity</h2></div>
          </header>
          <div>
            <form className='w3-container w3-margin' onSubmit={handleSubmit} >
              <div className='w3-container w3-center'><h3>Activity Information</h3></div>
              <div className='w3-container w3-margin' >
                <div className='w3-center'><label>Activity Name:</label></div>
                <input className='w3-input w3-round-large' type='text' placeholder='Activity Name' name='activityName' value={activityName} onChange={(e) => handleChange(e)}/>
              </div>
              <div className='w3-container w3-margin' >
                <div className='w3-center'><label>Activity Host:</label></div>
                <input className='w3-input w3-round-large' type='text' placeholder='Activity Host' name='host' value={host} onChange={(e) => handleChange(e)}/>
              </div>
              <div className='w3-container w3-margin w3-center' >
                <div className='w3-center'><label>Activity Date:</label></div>
                <Datetime value={date} timeFormat={false} dateFormat='MMMM DD, YYYY' onChange={(e) => handleDateChange(e)}>
                  <input name='date' type='text' readOnly/>
                </Datetime>
              </div>
              <div className='w3-row-padding w3-margin w3-center'>
                <div className='w3-half'>
                  <label>Start Time:</label>
                  <Datetime value={start} timeFormat='hh:mm A' dateFormat={false} onChange={(e) => handleStartChange(e)}>
                    <input name='start' type='text' readOnly/>
                  </Datetime>
                </div>
                <div className='w3-half'>
                  <label>End Time:</label>
                  <Datetime value={end} timeFormat='hh:mm A' dateFormat={false} onChange={(e) => handleEndChange(e)}>
                    <input name='end' type='text' readOnly/>
                  </Datetime>
                </div>
                <div className='w3-container w3-margin' >
                  <div className='w3-center'><label>Activity Description:</label></div>
                  <textarea className='w3-input w3-round-large' rows='5' name='description' value={description} onChange={(e) => handleChange(e)}/>
                </div>
              </div>
              <div className='w3-container w3-center w3-margin'>
                <button className='w3-button w3-round-large w3-red' type="submit">Save Activity</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScheduleAdd;
