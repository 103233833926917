import React, { Component } from 'react';

import HomeView from './HomeView';

class HomeContainer extends Component {

  render() {
    return (
      <div>
        <HomeView />
      </div>
    );
  }
}

export default HomeContainer;
