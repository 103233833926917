import React from 'react';
import { Link } from 'react-router-dom';
import Datetime from 'react-datetime';
import '../react-datetime.css';

import logo from '../logo-white.png';
import menu from '../images/ic_menu.png';
import close from '../images/ic_close.png';

const EditEvent = ({
  eventID, selectedEvent, goView, goInfo, goDetails, goSections, goBackground, sectionOptions, handleSectionChange,
  infoStyle, detailsStyle, sectionsStyle, backgroundStyle, infoHeadStyle, detailsHeadStyle, sectionsHeadStyle, backgroundHeadStyle,
  infoCheck, detailsCheck, sectionsCheck, handleEditChange, handleEditSubmit, handleStartChange, handleEndChange, handleBackground, handleBgSelect, handleBgChoice,
  name, mode, type, limit, start, end, email, phone, description, city, state, zip, name1, email1, phone1, name2, email2, phone2, background,
  section1, section2, section3, section4, custom, defaultBG1, defaultBG2, bg1Style, bg2Style, bgMessage, defaultBgStyle, customBgStyle,
}) => {

  return (
    <div>
      <div className='w3-top w3-border-bottom e-opacity'>
        <div className='w3-bar w3-display-container'>
          <div className='w3-bar-item w3-left'>
            <Link to='/' style={{cursor:'pointer'}}><img className='w3-left' src={logo} alt='Eventura logo' style={{height:75}} /></Link>
            <span className='w3-margin w3-left w3-xlarge w3-text-white'>EVENTURA</span>
          </div>
          <img src={menu} className='w3-bar-item w3-right w3-margin-top' alt='ic_menu' style={{height:40}} />
        </div>
      </div>
      <div style={{height:150}}></div>
      <div style={{width:'75%',margin:'auto'}}>
        <div className='w3-card w3-display-center w3-round-large w3-margin w3-border w3-text-white e-opacity'>
          <header className='w3-display-container w3-center'>
          <div className='w3-display-topright w3-margin'><button className='w3-button w3-circle w3-red'>
            <img src={close} onClick={goView} alt='ic_close' style={{width:15,height:15}} />
          </button></div>
          <div className='w3-container w3-center'><h2>Edit {name}</h2></div>
          </header>
          <div className='w3-row w3-eventura'>
            <div className='w3-quarter w3-center w3-padding w3-hover-eventura' style={infoHeadStyle} onClick={goInfo}>Information</div>
            <div className='w3-quarter w3-center w3-padding w3-hover-eventura' style={detailsHeadStyle} onClick={goDetails}>Details</div>
            <div className='w3-quarter w3-center w3-padding w3-hover-eventura' style={sectionsHeadStyle} onClick={goSections}>Sections</div>
            <div className='w3-quarter w3-center w3-padding w3-hover-eventura' style={backgroundHeadStyle} onClick={goBackground}>Background</div>
          </div>
          <div className='w3-animate-right' style={infoStyle}>
            <form className='w3-container w3-margin'>
              <div className='w3-container w3-center'><h3>{selectedEvent} General Information</h3></div>
              <div className='w3-container w3-center'><p>All fields with * are required to be completed before you can continue.</p></div>
              <div className='w3-container w3-margin' >
                <div className='w3-center'><label>Event Name: *</label></div>
                <input className='w3-input w3-round-large' type='text' placeholder='Event Name' required name='name' value={name} onChange={(e) => handleEditChange(e)}/>
              </div>
              <div className='w3-row-padding w3-margin'>
                <div className='w3-third'>
                  <label>Mode: *</label>
                  <select className='w3-select w3-round-large' required name='mode' value={mode} onChange={(e) => handleEditChange(e)}>
                    <option value='' defaultValue></option>
                    <option value='Private'>Private</option>
                    <option value='Public'>Public</option>
                  </select>
                </div>
                <div className='w3-third'>
                  <label>Type: *</label>
                  <select className='w3-select w3-round-large' required name='type' value={type} onChange={(e) => handleEditChange(e)}>
                    <option value='' defaultValue></option>
                    <option value='Business'>Business</option>
                    <option value='Family'>Family</option>
                    <option value='Religious'>Religious</option>
                    <option value='Social'>Social</option>
                    <option value='Wedding'>Wedding</option>
                  </select>
                </div>
                <div className='w3-third'>
                  <label>Guest Limit: *</label>
                  <select className='w3-select w3-round-large' required name='limit' value={limit} onChange={(e) => handleEditChange(e)}>
                    <option value='' defaultValue></option>
                    <option value='50'>Less than 50</option>
                    <option value='100'>50 - 100</option>
                    <option value='200'>Over 100</option>
                  </select>
                </div>
              </div>
              <div className='w3-row-padding w3-margin w3-center'>
                <div className='w3-half'>
                  <label>Start Date: *</label>
                  <Datetime value={start} timeFormat={false} dateFormat='MMMM DD, YYYY' onChange={(e) => handleStartChange(e)}>
                    <input name='start' type='text' readOnly/>
                  </Datetime>
                </div>
                <div className='w3-half'>
                  <label>End Date: *</label>
                  <Datetime value={end} timeFormat={false} dateFormat='MMMM DD, YYYY' onChange={(e) => handleEndChange(e)}>
                    <input name='end' type='text' readOnly/>
                  </Datetime>
                </div>
              </div>
              <div className='w3-container w3-center'><h3>Point of Contact</h3></div>
              <div className='w3-row-padding w3-margin'>
                <div className='w3-third'>
                  <label>Name 1: *</label>
                  <input className='w3-input w3-round-large w3-cell' type='text' placeholder='Name' required name='name1' value={name1} onChange={(e) => handleEditChange(e)}/>
                </div>
                <div className='w3-third'>
                  <label>Email 1: *</label>
                  <input className='w3-input w3-round-large w3-cell' type='email' placeholder='Email' required name='email1' value={email1} onChange={(e) => handleEditChange(e)}/>
                </div>
                <div className='w3-third'>
                  <label>Phone 1: *</label>
                  <input className='w3-input w3-round-large w3-cell' type='number' min='10' placeholder='Phone' required name='phone1' value={phone1} onChange={(e) => handleEditChange(e)}/>
                </div>
              </div>
              <div className='w3-row-padding w3-margin w3-border-top'>
                <div className='w3-third'>
                  <label>Name 2:</label>
                  <input className='w3-input w3-round-large w3-cell' type='text' placeholder='Name' name='name2' value={name2} onChange={(e) => handleEditChange(e)}/>
                </div>
                <div className='w3-third'>
                  <label>Email 2:</label>
                  <input className='w3-input w3-round-large w3-cell' type='email' placeholder='Email' name='email2' value={email2} onChange={(e) => handleEditChange(e)}/>
                </div>
                <div className='w3-third'>
                  <label>Phone 2:</label>
                  <input className='w3-input w3-round-large w3-cell' type='number' min='10' max='15' placeholder='Phone' name='phone2' value={phone2} onChange={(e) => handleEditChange(e)}/>
                </div>
              </div>
            </form>
          </div>
          <div className='w3-animate-right' style={detailsStyle}>
            <form className='w3-container w3-margin'>
              <div className='w3-container w3-center'><h3>{name} Event Details</h3></div>
              <div className='w3-container w3-margin' >
                <div className='w3-center'><label>Event Description: *</label></div>
                <textarea className='w3-input w3-round-large' rows='5' required name='description' value={description} onChange={(e) => handleEditChange(e)}/>
              </div>
              <div className='w3-container w3-center'><h3>Event Location</h3></div>
              <div className='w3-container w3-margin'>
                <div><label>City: *</label></div>
                <input className='w3-input w3-round-large w3-cell' type='text' placeholder='City' required name='city' value={city} onChange={(e) => handleEditChange(e)}/>
                <div><label>State: *</label></div>
                <select className='w3-select w3-round-large w3-cell' required name='state' value={state} onChange={(e) => handleEditChange(e)}>
                  <option value=""></option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
                <div><label>Zip Code: *</label></div>
                <input className='w3-input w3-round-large w3-cell' type='number' min='5' placeholder='Zip Code' required name='zip' value={zip} onChange={(e) => handleEditChange(e)}/>
              </div>
            </form>
          </div>
          <div className='w3-animate-right' style={sectionsStyle}>
            <form className='w3-container w3-margin'>
              <div className='w3-container w3-center'><h3>Mobile App Sections</h3></div>
              <div className='w3-container w3-margin'>
                <div><label>Section1:</label></div>
                <select className='w3-select w3-round-large' name='section1' value='Schedule' readOnly>
                  <option value='Schedule' defaultValue>Schedule (selected by default)</option>
                </select>
                <div><label>Section2:</label></div>
                <select className='w3-select w3-round-large' name='section2' value={section2} onChange={(e) => handleSectionChange(e)}>
                  <option value='' defaultValue></option>
                  <option value='Dining'>Dining</option>
                  <option value='Speaker'>Speaker</option>
                  <option value='Sponsor'>Sponsor</option>
                  <option value='Travel'>Travel</option>
                </select>
                <div><label>Section3:</label></div>
                <select className='w3-select w3-round-large' name='section3' value={section3} onChange={(e) => handleSectionChange(e)}>
                  <option value='' defaultValue></option>
                  <option value='Dining'>Dining</option>
                  <option value='Speaker'>Speaker</option>
                  <option value='Sponsor'>Sponsor</option>
                  <option value='Travel'>Travel</option>
                </select>
                <div><label>Section4:</label></div>
                <select className='w3-select w3-round-large' name='section4' value={section4} onChange={(e) => handleSectionChange(e)}>
                  <option value='' defaultValue></option>
                  <option value='Dining'>Dining</option>
                  <option value='Speaker'>Speaker</option>
                  <option value='Sponsor'>Sponsor</option>
                  <option value='Travel'>Travel</option>
                </select>
                <div><label>Custom Section:</label></div>
                <input className='w3-input w3-round-large' type='text' placeholder='Custom Section' name='custom' value={custom} onChange={(e) => handleSectionChange(e)}/>
              </div>
            </form>
          </div>
          <div className='w3-animate-right' style={backgroundStyle}>
            <form className='w3-container w3-margin'>
              <div className='w3-container w3-center'><h3>Event Background</h3></div>
              <div className='w3-container w3-margin w3-center'>
                <div className='w3-container w3-margin w3-center'>
                  <p>Current Background: </p>
                  <img src={background} alt='background' style={{height:100}} />
                </div>
                <div style={defaultBgStyle}>
                  <div><label>Choose from default images:</label></div>
                  <div className='w3-row-padding w3-margin w3-lightgrey'>
                    <div className='w3-half w3-hover-eventura w3-padding' style={bg1Style} ><img className='w3-border w3-hover-grayscale maxWidth' src={defaultBG1} name='BG1' value={defaultBG1} alt='background image1' onClick={(e) => handleBgSelect(e)}/></div>
                    <div className='w3-half w3-hover-eventura w3-padding' style={bg2Style} ><img className='w3-border w3-hover-grayscale maxWidth' src={defaultBG2} name='BG2' value={defaultBG2} alt='background image2' onClick={(e) => handleBgSelect(e)}/></div>
                  </div>
                </div>
                <div style={customBgStyle}>
                  <div><label>Upload your own background image:</label></div>
                  <div className='w3-container w3-center'><input className='w3-input w3-center' type='file' name='background' onChange={(e) => handleBackground(e)}/></div>
                </div>
                <div><button className='w3-button w3-margin w3-round-large w3-eventura' onClick={handleBgChoice}>{bgMessage}</button></div>
              </div>
            </form>
          </div>
          <div className='w3-container w3-center w3-margin'>
            <button className='w3-button w3-round-large w3-red w3-text-white' value={eventID} onClick={handleEditSubmit}>Complete {name} Edit</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditEvent;
