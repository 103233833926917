import React, { Component } from 'react';

import MainView from './MainView';

class MainContainer extends Component {

  constructor(props){
    super(props);
    this.state = {
      menuStyle: {display:'none'},
    }
    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  openMenu(e){
    e.preventDefault();
    this.setState({ menuStyle:{display:'block',right:0},})
  }

  closeMenu(e){
    e.preventDefault();
    this.setState({ menuStyle:{display:'none'},})
  }

  render() {
    return (
      <div>
        <MainView closeMenu={this.closeMenu} openMenu={this.openMenu} menuStyle={this.state.menuStyle} />
      </div>
    );
  }
}

export default MainContainer;
