import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import firebase from '../../Firebase';
import moment from 'moment';

import ScheduleAdd from './ScheduleAdd';
import ScheduleEdit from './ScheduleEdit';

import logo from '../../logo-white.png';
import menu from '../../images/ic_menu.png';
import close from '../../images/ic_close.png';
import add from '../../images/ic_add.png';

class ScheduleMain extends Component {
  constructor(props) {
    super(props);
    this.ref = firebase.firestore().collection('events');
    this.unsubscribe = null;
    this.state = {
      mainView: true, addView: false, editView: false, deleteView: false,
      allActivities: [],
      activitiesView: '',
      selectedActivity: '',
      activityID:'', activityName:'', host:'', date:moment(), start:moment(), end:moment(), description:'',
      dateChange:false, startChange:false, endChange:false, deleteStyle: {display:'none'},
    }
    this.goMain = this.goMain.bind(this);
    this.goAdd = this.goAdd.bind(this);
    this.goEdit = this.goEdit.bind(this);
    this.goDelete = this.goDelete.bind(this);
    this.closeDelete = this.closeDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
  }

  getActivities = () => {
    const {eventID} = this.props;
    const {goEdit, goDelete} = this;
    var newActivities = [];
    var actView = '';
    var query = this.ref.doc(eventID).collection('activities').where('eventID', '==', eventID);
    query.orderBy('date').orderBy('start').get().then((snapshot) => {
      snapshot.forEach((doc) => {
        const { activityID, eventID, eventName, activityName, host, date, start, end, description } = doc.data();
        newActivities.push({
          activityID, eventID, eventName, activityName, host, date, start, end, description
        });
      });
    });
    setTimeout(() => {
      if (newActivities.length > 0) {
        this.setState({allActivities:newActivities});
        const {allActivities} = this.state;
        actView = allActivities.map(activity => (
          <div className='w3-container w3-margin w3-padding w3-card-4 w3-eventura' key={activity.activityID}>
            <div className='w3-center w3-margin-bottom'><h4>Activity: {activity.activityName}</h4></div>
            <div className='w3-row-padding w3-margin-top'>
              <div className='w3-quarter'><div className='w3-left-align w3-margin-top'>{activity.date}</div></div>
              <div className='w3-quarter'><div className='w3-left-align w3-margin-top'>Start: {activity.start}</div></div>
              <div className='w3-quarter'><div className='w3-left-align w3-margin-top'>End: {activity.end}</div></div>
              <div className='w3-quarter'>
                <button className='w3-button w3-block w3-round-large w3-grey w3-text-white w3-margin-bottom' onClick={goEdit} value={activity.activityID} >Edit</button>
                <button className='w3-button w3-block w3-round-large w3-red w3-text-white' onClick={goDelete} value={activity.activityID}>Delete</button>
              </div>
            </div>
          </div>
        ))
        this.setState({activitiesView:actView});
      } else {
        actView = <div className='w3-container w3-margin'><p>Click the "+" above to add your first Activity</p></div>
        this.setState({activitiesView:actView});
      }
    }, 500)
  }

  componentDidMount() {
    this.unsubscribe = this.getActivities();
  }

  componentWillUnmount() {
    this.unsubscribe = null;
  }

  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  handleDateChange(date) {
    this.setState({date, dateChange:true});
  }

  handleStartChange(start) {
    this.setState({start, startChange:true});
  }

  handleEndChange(end) {
    this.setState({end, endChange:true});
  }

  handleSubmit(e) {
    e.preventDefault();
    const {date, start, end} = this.state;
    const {eventID} = this.props;
    var newDate = date.format('MMMM DD, YYYY');
    var newStart = start.format('hh:mm A');
    var newEnd = end.format('hh:mm A');
    var data = {
      eventID: this.props.eventID,
      eventName: this.props.eventName,
      activityName: this.state.activityName,
      host: this.state.host,
      date: newDate,
      start: newStart,
      end: newEnd,
      description: this.state.description,
    }
    this.setState({dateChange:false, startChange:false, endChange:false,});
    setTimeout(() => {
      var actID;
      this.ref.doc(eventID).collection('activities').add(data).then((docRef) => {
        actID = docRef.id;
        this.setState({activityID: actID});
      }).catch((error) => {
        console.error('error adding activity: ',error);
      }).then(() => {
        this.ref.doc(eventID).collection('activities').doc(actID).update({
          activityID: actID
        });
        this.setState({activityID:actID});
      })
      this.unsubscribe = null;
      this.goMain(e);
    }, 500)
  }

  handleEditChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  handleEditSubmit(e) {
    e.preventDefault();
    const {activityID, date, start, end, dateChange, startChange, endChange} = this.state;
    const {eventID} = this.props;
    var newDate, newStart, newEnd;
    if (dateChange) {
      newDate = date.format('MMMM DD, YYYY');
    } else {
      newDate = date;
    }
    if (startChange) {
      newStart = start.format('hh:mm A');
    } else {
      newStart = start;
    }
    if (endChange) {
      newEnd = end.format('hh:mm A');
    } else {
      newEnd = end;
    }
    var data = {
      eventID: this.props.eventID,
      eventName: this.props.eventName,
      activityName: this.state.activityName,
      host: this.state.host,
      date: newDate,
      start: newStart,
      end: newEnd,
      description: this.state.description,
    }
    setTimeout(() => {
      this.ref.doc(eventID).collection('activities').doc(activityID).update(data);
    }, 100)
    this.unsubscribe = null;
    this.goMain(e);
  }

  handleDelete(e) {
    e.preventDefault();
    const {activityID} = this.state;
    const {eventID} = this.props;
    this.ref.doc(eventID).collection('activities').doc(activityID).delete().then(() => {
      console.log('activity deleted');
      this.setState({deleteStyle: {display:'none'},});
    }).catch((error) => {
      console.error('error deleting activity');
    })
    this.unsubscribe = null;
    this.goMain(e);
  }

  goMain(e) {
    e.preventDefault();
    this.unsubscribe = this.getActivities();
    setTimeout(() => {
      this.setState({
        mainView: true,
        addView: false,
        editView: false,
        deleteView: false,
      })
    }, 500)
  }

  goAdd(e) {
    e.preventDefault();
    this.setState({
      mainView: false,
      addView: true,
      editView: false,
      deleteView: false,
      activityName:'', host:'', date:moment(), start:moment(), end:moment(), description:'',
    })
  }

  goEdit(e) {
    e.preventDefault();
    console.log('Edit pressed');
    const {allActivities} = this.state;
    const key = e.target.value;
    allActivities.forEach((doc) => {
      if (doc.activityID === key) {
        this.setState({
          selectedActivity: doc,
          activityID: doc.activityID,
          activityName: doc.activityName,
          host: doc.host,
          date: doc.date,
          start: doc.start,
          end: doc.end,
          description: doc.description,
        })
      }
    })
    setTimeout(() => {
      this.setState({
        mainView: false,
        addView: false,
        editView: true,
        deleteView: false,
      })
    }, 500)
  }

  goDelete(e) {
    e.preventDefault();
    const {allActivities} = this.state;
    const key = e.target.value;
    allActivities.forEach((doc) => {
      if (doc.activityID === key) {
        this.setState({
          selectedActivity: doc,
          activityID: doc.activityID,
          activityName: doc.activityName,
          host: doc.host,
          date: doc.date,
          start: doc.start,
          end: doc.end,
          description: doc.description,
        })
      }
    })
    this.setState({
      deleteView: true,
      deleteStyle: {display:'block'},
    })
  }

  closeDelete(e) {
    e.preventDefault();
    this.setState({deleteStyle: {display:'none'},});
  }

  render() {
    const {eventName, eStart, eEnd, selectedBG, goView, } = this.props;
    const {mainView, addView, editView, activitiesView, deleteStyle, activityName, description} = this.state;
    const {goAdd, closeDelete, handleDelete} = this;
    let view;
    if (mainView) {
      view = (
        <div>
          <div className='w3-top w3-border-bottom e-opacity'>
            <div className='w3-bar w3-display-container'>
              <div className='w3-bar-item w3-left'>
                <Link to='/' style={{cursor:'pointer'}}><img className='w3-left' src={logo} alt='Eventura logo' style={{height:75}} /></Link>
                <span className='w3-margin w3-left w3-xlarge w3-text-white'>EVENTURA</span>
              </div>
              <img src={menu} className='w3-bar-item w3-right w3-margin-top' alt='ic_menu' style={{height:40}} />
            </div>
          </div>
          <div style={{height:150}}></div>
          <div style={{width:'75%',margin:'auto'}}>
            <div className='w3-card w3-display-center w3-round-large w3-margin w3-border w3-text-white e-opacity w3-animate-zoom'>
              <header className='w3-display-container w3-center'>
                <div className='w3-display-topright w3-margin'><button className='w3-button w3-circle w3-red' onClick={goView}>
                  <img src={close} alt='ic_close' style={{width:15,height:15}} />
                </button></div>
                <div className='w3-container w3-center'><h2>{eventName} Schedule</h2></div>
              </header>
              <div className='w3-display-container'>
                <div className='w3-round-large'>
                  <img className='w3-opacity-min' src={selectedBG} alt='background' style={{height:200,width:'100%'}} />
                </div>
                <div className='w3-display-topmiddle' style={{width:'90%'}}>
                  <h3 className='w3-center'>Event Dates: {eStart} - {eEnd}</h3>
                  <h3 className='w3-center'>{eventName} Activities</h3>
                  <div className='w3-center'><button className='w3-button w3-circle w3-eventura'>
                    <img src={add} onClick={goAdd} alt='ic_add' style={{width:20,height:20}} />
                  </button></div>
                </div>
                <div className='w3-modal e-opacity' style={deleteStyle}>
                  <div className='w3-modal-content w3-eventura w3-animate-zoom w3-card-4'>
                    <header className='w3-container w3-indigo'>
                      <span className='w3-button w3-xlarge w3-display-topright' onClick={closeDelete}>&times;</span>
                      <h3>Are you sure you want to delete {activityName}?</h3>
                    </header>
                    <div className='w3-container' style={{height:200}}>
                      <div className='w3-display-middle'><p>{description}</p></div>
                    </div>
                    <footer className='w3-container w3-row'>
                      <div className='w3-margin'>
                        <div className='w3-half w3-padding'><button className='w3-button w3-block w3-round-large w3-grey w3-text-white' onClick={closeDelete}>No, go back!</button></div>
                        <div className='w3-half w3-padding'><button className='w3-button w3-block w3-round-large w3-red w3-text-white' onClick={handleDelete}>Yes, delete!</button></div>
                      </div>
                    </footer>
                  </div>
                </div>
                <div className='w3-container w3-center' >
                {activitiesView}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (addView) {
      view = (
        <div>
          <ScheduleAdd
            goMain={this.goMain} eventName={this.props.eventName} handleChange={this.handleChange} handleSubmit={this.handleSubmit}
            handleDateChange={this.handleDateChange} handleStartChange={this.handleStartChange} handleEndChange={this.handleEndChange}
            activityName={this.state.activityName} host={this.state.host} date={this.state.date} start={this.state.start} end={this.state.end} description={this.state.description}
          />
        </div>
      )
    } else if (editView) {
      view = (
        <div>
          <ScheduleEdit
            goMain={this.goMain} eventName={this.props.eventName} handleEditChange={this.handleEditChange} handleEditSubmit={this.handleEditSubmit}
            handleDateChange={this.handleDateChange} handleStartChange={this.handleStartChange} handleEndChange={this.handleEndChange}
            selectedActivity={this.state.selectedActivity} activityName={this.state.activityName} host={this.state.host} date={this.state.date} start={this.state.start} end={this.state.end} description={this.state.description}
          />
        </div>
      )
    }

    return (
      view
    );
  }
}

export default ScheduleMain;
