import React, { Component } from 'react';
import firebase from '../../Firebase';
import {store} from '../../Firebase/firebase.js'
import moment from 'moment';

import CreateEvent from './CreateEvent';
import ViewEvent from './ViewEvent';
import DiningMain from '../Sections/DiningMain';
import ScheduleMain from '../Sections/ScheduleMain';
import SpeakerMain from '../Sections/SpeakerMain';
import SponsorMain from '../Sections/SponsorMain';
import TravelMain from '../Sections/TravelMain';
import CustomMain from '../Sections/CustomMain';

import draft from '../../images/switchDraft2.png';
import live from '../../images/switchPublish.png';

const sgMail = require('@sendgrid/mail');
sgMail.setApiKey(process.env.SENDGRID_API_KEY);

class CreateEventContainer extends Component {
  constructor(props) {
    super(props);
    this.ref = firebase.firestore().collection('events');
    this.ref2 = firebase.firestore().collection('users');
    this.state = {
      business1:'', business2:'', family1:'', family2:'', religious1:'', religious2:'', social1:'', social2:'', wedding1:'', wedding2:'',
      eventsView: false, createEvent: true, viewEvent: false, diningView:false, scheduleView: false, speakerView:false, sponsorView:false, travelView:false, customView: false,
      infoSet: false, detailsSet: false, sectionsSet: false, backgroundSet: false, startChange:false, endChange:false,
      publish: false, toggle: draft, bgChoice: 'custom', bgMessage:'Choose your own background image',
      creatorID: '', eventID: '', eventCode: '', name: '', mode: '', type: '', limit: '',
      start: moment(), end: moment(), description: '', city: '', state: '', zip: '', timeStamp:'',
      section1: 'Schedule', section2: '', section3: '', section4: '', custom: '',
      name1: '', phone1: '', email1: '', name2: '', phone2: '', email2: '', defaultBG1: '', defaultBG2: '', selectedBG: '',
      infoCheck: {display:'none'}, detailsCheck: {display:'none'}, sectionsCheck: {display:'none'},
      infoStyle: {display:'block'}, detailsStyle: {display:'none'}, sectionsStyle: {display:'none'}, backgroundStyle: {display:'none'},
      infoHeadStyle: {color:'white'}, detailsHeadStyle: {color:'#0F0E85'}, sectionsHeadStyle: {color:'#0F0E85'}, backgroundHeadStyle: {color:'#0F0E85'},
      infoViewStyle: {display:'block'}, dashboardStyle: {display:'none'}, bg1Style: {cursor:'pointer'}, bg2Style: {cursor:'pointer'},
      infoViewHead: {background:'#0f0e85', cursor:'pointer'}, dashboardHead: {background:'#3e528d', cursor:'pointer'},
      defaultBgStyle: {display:'block'}, customBgStyle: {display:'none'},
      isSection2: false, isSection3: false, isSection4: false, isCustom: false,
      modeStyle: {display:'none'}, typeStyle: {display:'none'}, limitStyle: {display:'none'},
    }
    this.closeModal = this.closeModal.bind(this);
    this.goModeHelp = this.goModeHelp.bind(this);
    this.goTypeHelp = this.goTypeHelp.bind(this);
    this.goLimitHelp = this.goLimitHelp.bind(this);
    this.goInfo = this.goInfo.bind(this);
    this.goDetails = this.goDetails.bind(this);
    this.goSections = this.goSections.bind(this);
    this.goBackground = this.goBackground.bind(this);
    this.goInfoView = this.goInfoView.bind(this);
    this.goDashboard = this.goDashboard.bind(this);
    this.setInfo = this.setInfo.bind(this);
    this.setDetails = this.setDetails.bind(this);
    this.setSections = this.setSections.bind(this);
    this.setBackground = this.setBackground.bind(this);
    this.setPublish = this.setPublish.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.handleSection = this.handleSection.bind(this);
    this.handleSectionChange = this.handleSectionChange.bind(this);
    this.handleBackground = this.handleBackground.bind(this);
    this.handleBgSelect = this.handleBgSelect.bind(this);
    this.handleBgChoice = this.handleBgChoice.bind(this);
    this.goView = this.goView.bind(this);
    this.getBackgrounds = this.getBackgrounds.bind(this);
  }

  componentDidMount() {
    this.getBackgrounds();
  }

  getBackgrounds() {
    var business1Url = store.ref('images/background/business1.png');
    var business2Url = store.ref('images/background/business2.png');
    var family1Url = store.ref('images/background/family1.png');
    var family2Url = store.ref('images/background/family2.png');
    var religious1Url = store.ref('images/background/religious1.png');
    var religious2Url = store.ref('images/background/religious2.png');
    var social1Url = store.ref('images/background/social1.png');
    var social2Url = store.ref('images/background/social2.png');
    var wedding1Url = store.ref('images/background/wedding1.png');
    var wedding2Url = store.ref('images/background/wedding2.png');
    business1Url.getDownloadURL().then((url) => {
      this.setState({business1:url});
    })
    business2Url.getDownloadURL().then((url) => {
      this.setState({business2:url});
    })
    family1Url.getDownloadURL().then((url) => {
      this.setState({family1:url});
    })
    family2Url.getDownloadURL().then((url) => {
      this.setState({family2:url});
    })
    religious1Url.getDownloadURL().then((url) => {
      this.setState({religious1:url});
    })
    religious2Url.getDownloadURL().then((url) => {
      this.setState({religious2:url});
    })
    social1Url.getDownloadURL().then((url) => {
      this.setState({social1:url});
    })
    social2Url.getDownloadURL().then((url) => {
      this.setState({social2:url});
    })
    wedding1Url.getDownloadURL().then((url) => {
      this.setState({wedding1:url});
    })
    wedding2Url.getDownloadURL().then((url) => {
      this.setState({wedding2:url});
    })
  }

  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  handleStartChange(start) {
    this.setState({start});
  }

  handleEndChange(end) {
    this.setState({end});
  }

  handleSectionChange(e) {
    const {section2, section3, section4} = this.state;
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'section2') {
      if ((value === section3) || (value === section4)) {
        if (value === '') {
          this.setState({[name]: value});
        } else {
          console.log('section taken!');
        }
      } else {
        this.setState({[name]: value});
      }
    } else if (name === 'section3') {
      if ((value === section2) || (value === section4)) {
        if (value === '') {
          this.setState({[name]: value});
        } else {
          console.log('section taken!');
        }
      } else {
        this.setState({[name]: value});
      }
    } else if (name === 'section4') {
      if ((value === section2) || (value === section3)) {
        if (value === '') {
          this.setState({[name]: value});
        } else {
          console.log('section taken!');
        }
      } else {
        this.setState({[name]: value});
      }
    } else if (name === 'custom') {
      if ((value === section2) || (value === section3) || (value === section4)) {
        if (value === '') {
          this.setState({[name]: value});
        } else {
          console.log('section taken!');
        }
      } else {
        this.setState({[name]: value});
      }
    }
  }

  handleBgChoice(e) {
    e.preventDefault();
    const {bgChoice} = this.state;
    if (bgChoice === 'default') {
      console.log('bgChoice: ',bgChoice);
      this.setState({
        bgChoice: 'custom',
        bgMessage: 'Choose your own background image',
        defaultBgStyle: {display:'block'},
        customBgStyle: {display:'none'}
      })
    } else {
      console.log('bgChoice: ',bgChoice);
      this.setState({
        bgChoice: 'default',
        bgMessage: 'Choose default background image',
        defaultBgStyle: {display:'none'},
        customBgStyle: {display:'block'}
      })
    }
  }

  handleBgSelect(e) {
    const {defaultBG1, defaultBG2} = this.state;
    const name = e.target.name;
    let value;
    if (name ==='BG1') {
      this.setState({bg1Style:{background:'#3e528d'},bg2Style:{background:'none'}});
      value = defaultBG1
    }
    else if (name ==='BG2') {
      this.setState({bg1Style:{background:'none'},bg2Style:{background:'#3e528d'}});
      value = defaultBG2
    }
    this.setState({selectedBG:value});
  }

  handleBackground(e) {
    let photo = e.target.files[0];
    this.setState({selectedBG:photo});
  }

  setInfo(e) {
    e.preventDefault();
    const {type, business1, business2, family1, family2, religious1, religious2, social1, social2, wedding1, wedding2} = this.state;
    if (type === 'Business') {this.setState({defaultBG1:business1, defaultBG2:business2});}
    else if (type === 'Family') {this.setState({defaultBG1:family1, defaultBG2:family2});}
    else if (type === 'Religious') {this.setState({defaultBG1:religious1, defaultBG2:religious2});}
    else if (type === 'Social') {this.setState({defaultBG1:social1, defaultBG2:social2});}
    else if (type === 'Wedding') {this.setState({defaultBG1:wedding1, defaultBG2:wedding2});}
    this.setState({
      infoStyle: {display:'none'},
      infoHeadStyle: {cursor:'pointer'},
      detailsStyle: {display:'block'},
      detailsHeadStyle: {color:'white',cursor:'pointer'},
      infoSet: true,
      infoCheck: {display:'inline',height:25},
    });
  }

  setDetails(e) {
    e.preventDefault();
    this.setState({
      detailsStyle: {display:'none'},
      sectionsStyle: {display:'block'},
      sectionsHeadStyle: {color:'white',cursor:'pointer'},
      detailsSet: true,
      detailsCheck: {display:'inline',height:25},
    });
  }

  setSections(e) {
    e.preventDefault();
    const {section2, section3, section4, custom} = this.state;
    if (section2 !== '') {this.setState({isSection2:true});}
    if (section3 !== '') {this.setState({isSection3:true});}
    if (section4 !== '') {this.setState({isSection4:true});}
    if (custom !== '') {this.setState({isCustom:true});}
    this.setState({
      sectionsStyle: {display:'none'},
      backgroundStyle: {display:'block'},
      backgroundHeadStyle: {color:'white',cursor:'pointer'},
      sectionsSet: true,
      sectionsCheck: {display:'inline',height:25},
    });
  }

  setBackground(e) {
    e.preventDefault();
    const {name, start, end} = this.state;
    var newStart = start.format('MMMM DD, YYYY');
    var newEnd = end.format('MMMM DD, YYYY');
    var timeStamp = moment().format();
    var rand = Math.floor(Math.random() * Math.floor(1000));
    var evCode = name.slice(0,3) + '-' + rand;
    console.log('evCode:', evCode);
    this.setState({
      backgroundSet: true,
      createEvent: false,
      viewEvent: true,
      start: newStart,
      end: newEnd,
      eventCode: evCode,
    });
    var data = {
      creatorID: this.props.userID, eventCode: evCode, name: this.state.name, mode: this.state.mode, type: this.state.type, limit: this.state.limit,
      start: newStart, end: newEnd, description: this.state.description, city: this.state.city, state: this.state.state, zip: this.state.zip, publish:this.state.publish,
      name1: this.state.name1, email1:this.state.email1, phone1:this.state.phone1, name2:this.state.name2, email2:this.state.email2, phone2:this.state.phone2,
      section1: 'Schedule', section2: this.state.section2, section3: this.state.section3, section4: this.state.section4, custom: this.state.custom, timeStamp: timeStamp,
    }

    setTimeout(() => {
      const {selectedBG, bgChoice} = this.state;
      var eventID;
      this.ref.add(data).then((docRef) => {
        console.log('event saved with ID: ',docRef.id);
        eventID = docRef.id;
        this.setState({eventID: eventID});
      }).catch((error) => {
        console.error('error adding event: ',error);
      }).then(() => {
        var uploadTask;
        if (bgChoice === 'default') {
          uploadTask = store.ref('images/background').child(eventID).child('/background.jpg').put(selectedBG);
          uploadTask.on('state_changed', (snapshot) => {
            console.log('state changed...');
          }, (error) => {
            console.log(error);
          }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((url) => {
              this.ref.doc(eventID).update({
                eventID: eventID, background: url
              });
              this.setState({
                eventID: eventID, background: url
              });
            })
          })
        } else if (bgChoice === 'custom') {
          this.ref.doc(eventID).update({
            eventID: eventID, background: selectedBG
          });
          this.setState({
            eventID: eventID, background: selectedBG
          });
        }
      })
    }, 100)
  }

  handleSection(e) {
    e.preventDefault();
    const section = e.target.value;
    if(section === 'Dining') {this.setState({diningView:true, scheduleView: false, speakerView:false, sponsorView:false, travelView:false, customView: false, viewEvent:false})}
    else if(section === 'Schedule') {this.setState({diningView:false, scheduleView: true, speakerView:false, sponsorView:false, travelView:false, customView: false, viewEvent:false})}
    else if(section === 'Speaker') {this.setState({diningView:false, scheduleView: false, speakerView:true, sponsorView:false, travelView:false, customView: false, viewEvent:false})}
    else if(section === 'Sponsor') {this.setState({diningView:false, scheduleView: false, speakerView:false, sponsorView:true, travelView:false, customView: false, viewEvent:false})}
    else if(section === 'Travel') {this.setState({diningView:false, scheduleView: false, speakerView:false, sponsorView:false, travelView:true, customView: false, viewEvent:false})}
    else{this.setState({diningView:false, scheduleView: false, speakerView:false, sponsorView:false, travelView:false, customView: true, viewEvent:false})}
  }

  setPublish(e) {
    e.preventDefault();
    const {publish} = this.state;
    if (publish) {
      this.setState({
        publish: false,
        toggle: draft,
      });
    } else {
      this.setState({
        publish: true,
        toggle: live,
      });
    }
  }

  closeModal(e) {
    e.preventDefault();
    this.setState({
      modeStyle: {display:'none'},
      typeStyle: {display:'none'},
      limitStyle: {display:'none'},
    })
  }

  goModeHelp(e) {
    e.preventDefault();
    this.setState({
      modeStyle: {display:'block'},
    })
  }

  goTypeHelp(e) {
    e.preventDefault();
    this.setState({
      typeStyle: {display:'block'},
    })
  }

  goLimitHelp(e) {
    e.preventDefault();
    this.setState({
      limitStyle: {display:'block'},
    })
  }

  goInfo(e) {
    e.preventDefault();
    this.setState({
      infoStyle: {display:'block'},
      detailsStyle: {display:'none'},
      sectionsStyle: {display:'none'},
      backgroundStyle: {display:'none'},
    });
  }

  goDetails(e) {
    e.preventDefault();
    const{infoSet} = this.state;
    if(infoSet) {
      this.setState({
        infoStyle: {display:'none'},
        detailsStyle: {display:'block'},
        sectionsStyle: {display:'none'},
        backgroundStyle: {display:'none'},
      });
    }
  }

  goSections(e) {
    e.preventDefault();
    const{detailsSet} = this.state;
    if(detailsSet) {
      this.setState({
        infoStyle: {display:'none'},
        detailsStyle: {display:'none'},
        sectionsStyle: {display:'block'},
        backgroundStyle: {display:'none'},
      });
    }
  }

  goBackground(e) {
    e.preventDefault();
    const{sectionsSet} = this.state;
    if(sectionsSet) {
      this.setState({
        infoStyle: {display:'none'},
        detailsStyle: {display:'none'},
        sectionsStyle: {display:'none'},
        backgroundStyle: {display:'block'},
      });
    }
  }

  goView(e) {
    e.preventDefault();
    this.setState({
      viewEvent: true,
      scheduleView: false,
      customView: false,
    })
  }

  goInfoView(e) {
    e.preventDefault();
    this.setState({
      infoViewStyle: {display:'block'},
      dashboardStyle: {display:'none'},
      infoViewHead: {background:'#0f0e85',cursor:'pointer'},
      dashboardHead: {background:'#3e528d',cursor:'pointer'},
    })
  }

  goDashboard(e) {
    e.preventDefault();
    this.setState({
      infoViewStyle: {display:'none'},
      dashboardStyle: {display:'block'},
      infoViewHead: {background:'#3e528d',cursor:'pointer'},
      dashboardHead: {background:'#0f0e85',cursor:'pointer'},
    })
  }

  render() {
    const {createEvent, viewEvent, diningView, scheduleView, speakerView, sponsorView, travelView, customView} = this.state;
    let view;
    if (createEvent) {
      view = (
        <div>
          <CreateEvent
            goInfo={this.goInfo} goDetails={this.goDetails} goSections={this.goSections} goBackground={this.goBackground}
            setInfo={this.setInfo} setDetails={this.setDetails} setSections={this.setSections} setBackground={this.setBackground} handleBgSelect={this.handleBgSelect} handleBgChoice={this.handleBgChoice}
            handleChange={this.handleChange} handleStartChange={this.handleStartChange} handleEndChange={this.handleEndChange} handleBackground={this.handleBackground} handleSectionChange={this.handleSectionChange}
            infoStyle={this.state.infoStyle} detailsStyle={this.state.detailsStyle} sectionsStyle={this.state.sectionsStyle} backgroundStyle={this.state.backgroundStyle}
            infoHeadStyle={this.state.infoHeadStyle} detailsHeadStyle={this.state.detailsHeadStyle} sectionsHeadStyle={this.state.sectionsHeadStyle} backgroundHeadStyle={this.state.backgroundHeadStyle}
            infoCheck={this.state.infoCheck} detailsCheck={this.state.detailsCheck} sectionsCheck={this.state.sectionsCheck}
            eventCode={this.state.eventCode}
            name={this.state.name} mode={this.state.mode} type={this.state.type} limit={this.state.limit}
            start={this.state.start} end={this.state.end} email={this.state.email} phone={this.state.phone}
            description={this.state.description} city={this.state.city} state={this.state.state} zip={this.state.zip}
            name1={this.state.name1} email1={this.state.email1} phone1={this.state.phone1}
            name2={this.state.name2} email2={this.state.email2} phone2={this.state.phone2}
            section1={this.state.section1} section2={this.state.section2} section3={this.state.section3} section4={this.state.section4} custom={this.state.custom}
            defaultBG1={this.state.defaultBG1} defaultBG2={this.state.defaultBG2} bg1Style={this.state.bg1Style} bg2Style={this.state.bg2Style}
            defaultBgStyle={this.state.defaultBgStyle} customBgStyle={this.state.customBgStyle} bgMessage={this.state.bgMessage}
            closeModal={this.closeModal} goModeHelp={this.goModeHelp} goLimitHelp={this.goLimitHelp} goTypeHelp={this.goTypeHelp}
            modeStyle={this.state.modeStyle} typeStyle={this.state.typeStyle} limitStyle={this.state.limitStyle}
          />
        </div>
      )
    } else if (viewEvent) {
      view = (
        <div>
          <ViewEvent
            goEdit={this.goEdit} goInfoView={this.goInfoView} goDashboard={this.goDashboard} handleSection={this.handleSection}
            setPublish={this.setPublish} toggle={this.state.toggle}
            infoViewStyle={this.state.infoViewStyle} dashboardStyle={this.state.dashboardStyle}
            infoViewHead={this.state.infoViewHead} dashboardHead={this.state.dashboardHead}
            eventID={this.state.eventID} eventCode={this.state.eventCode} name={this.state.name} mode={this.state.mode} type={this.state.type} limit={this.state.limit}
            start={this.state.start} end={this.state.end} description={this.state.description}
            city={this.state.city} state={this.state.state} zip={this.state.zip} background={this.state.background} publish={this.state.publish}
            name1={this.state.name1} email1={this.state.email1} phone1={this.state.phone1}
            name2={this.state.name2} email2={this.state.email2} phone2={this.state.phone2}
            section1={this.state.section1} section2={this.state.section2} section3={this.state.section3} section4={this.state.section4} custom={this.state.custom}
            isSection2={this.state.isSection2} isSection3={this.state.isSection3} isSection4={this.state.isSection4} isCustom={this.state.isCustom}
          />
        </div>
      )
    } else if (diningView) {
      view = (
        <div>
          <DiningMain
            eventID={this.state.eventID} eventName={this.state.name} eStart={this.state.start} eEnd={this.state.end}
            goView={this.goView} selectedBG={this.state.background}
          />
        </div>
      )
    } else if (scheduleView) {
      view = (
        <div>
          <ScheduleMain
            eventID={this.state.eventID} eventName={this.state.name} eStart={this.state.start} eEnd={this.state.end}
            goView={this.goView} selectedBG={this.state.selectedBG}
          />
        </div>
      )
    } else if (speakerView) {
      view = (
        <div>
          <SpeakerMain
            eventID={this.state.eventID} eventName={this.state.name} eStart={this.state.start} eEnd={this.state.end}
            goView={this.goView} selectedBG={this.state.background}
          />
        </div>
      )
    } else if (sponsorView) {
      view = (
        <div>
          <SponsorMain
            eventID={this.state.eventID} eventName={this.state.name} eStart={this.state.start} eEnd={this.state.end}
            goView={this.goView} selectedBG={this.state.background}
          />
        </div>
      )
    } else if (travelView) {
      view = (
        <div>
          <TravelMain
            eventID={this.state.eventID} eventName={this.state.name} eStart={this.state.start} eEnd={this.state.end}
            goView={this.goView} selectedBG={this.state.background}
          />
        </div>
      )
    } else if (customView) {
      view = (
        <div>
          <CustomMain
            eventID={this.state.eventID} eventName={this.state.name} eStart={this.state.start} eEnd={this.state.end} customLabel={this.state.customLabel}
            goView={this.goView} selectedBG={this.state.background}
          />
        </div>
      )
    }
    return (
      view
    );
  }
}

export default CreateEventContainer;
