import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../logo-white.png';
import menu from '../../images/ic_menu.png';
import close from '../../images/ic_close.png';

const ViewEvent = ({
  goEdit, goInfoView, goDashboard, setPublish, infoViewStyle, dashboardStyle, infoViewHead, dashboardHead, toggle,
  isSection2, isSection3, isSection4, isCustom, handleSection,
  eventID, eventCode, name, mode, type, limit, start, end, description, city, state, zip, name1, email1, phone1, name2, email2, phone2,
  section1, section2, section3, section4, custom, background,
}) => {

  return (
    <div>
      <div className='w3-top w3-border-bottom e-opacity'>
        <div className='w3-bar w3-display-container'>
          <div className='w3-bar-item w3-left'>
            <Link to='/' style={{cursor:'pointer'}}><img className='w3-left' src={logo} alt='Eventura logo' style={{height:75}} /></Link>
            <span className='w3-margin w3-left w3-xlarge w3-text-white'>EVENTURA</span>
          </div>
          <img src={menu} className='w3-bar-item w3-right w3-margin-top' alt='ic_menu' style={{height:40}} />
        </div>
      </div>
      <div style={{height:150}}></div>
      <div style={{width:'75%',margin:'auto'}}>
        <div className='w3-card w3-display-center w3-round-large w3-margin w3-border w3-text-white e-opacity w3-animate-zoom'>
          <header className='w3-display-container w3-center'>
            <div className='w3-display-topright w3-margin'>
              <Link to='/events' className='w3-button w3-circle w3-red'><img src={close} alt='close' style={{width:15,height:15}} /></Link>
            </div>
            <div className='w3-container w3-center'><h2>View {name}</h2></div>
          </header>
          <div className='w3-row w3-eventura'>
            <div className='w3-half w3-center w3-padding w3-hover-eventura' style={infoViewHead} onClick={goInfoView}>Information </div>
            <div className='w3-half w3-center w3-padding w3-hover-eventura' style={dashboardHead} onClick={goDashboard}>Dashboard </div>
            </div>
          <div className='w3-display-container w3-animate-bottom' style={infoViewStyle}>
            <div className='w3-container w3-center'><h3>Event Information</h3></div>
            <div className='w3-container w3-margin' >
              <div className='w3-center'><label>Event Code: </label><span>{eventCode}</span></div>
            </div>
            <div className='w3-row-padding w3-margin'>
              <div className='w3-third w3-center'><label>Mode: </label><span>{mode}</span></div>
              <div className='w3-third w3-center'><label>Type: </label><span>{type}</span></div>
              <div className='w3-third w3-center'><label>Guest Limit: </label><span>{limit}</span></div>
            </div>
            <div className='w3-container w3-center'><h3>Event Details</h3></div>
            <div className='w3-container w3-margin' >
              <div className='w3-center'><label>Event Description:</label></div>
              <p className='w3-center'>{description}</p>
              <div className='w3-row-padding'>
                <div className='w3-half'>
                  <div className='w3-center'><label>Event Date:</label></div>
                  <p className='w3-center'>{start} - {end}</p>
                </div>
                <div className='w3-half'>
                  <div className='w3-center'><label>Event Location:</label></div>
                  <p className='w3-center'>{city}, {state} {zip}</p>
                </div>
              </div>
            </div>
            <div className='w3-container w3-center'><h3>Eventura App Sections</h3></div>
            <div className='w3-row-padding w3-margin'>
              <div className='w3-third w3-center w3-padding' style={{height:50}}>
                <button className='w3-button w3-red w3-round' style={{display:'block'}} value={section1} onClick={(e) => handleSection(e)}>{section1}</button>
              </div>
              {isSection2 ? (
                <div className='w3-third w3-center w3-padding' style={{height:50}}>
                  <button className='w3-button w3-red w3-round' style={{display:'block'}} value={section2} onClick={(e) => handleSection(e)}>{section2}</button>
                </div>
              ):(
                <div style={{display:'none'}}></div>
              )}
              {isSection3 ? (
                <div className='w3-third w3-center w3-padding' style={{height:50}}>
                  <button className='w3-button w3-red w3-round' style={{display:'block'}} value={section3} onClick={(e) => handleSection(e)}>{section3}</button>
                </div>
              ):(
                <div style={{display:'none'}}></div>
              )}
              {isSection4 ? (
                <div className='w3-third w3-center w3-padding' style={{height:50}}>
                  <button className='w3-button w3-red w3-round' style={{display:'block'}} value={section4} onClick={(e) => handleSection(e)}>{section4}</button>
                </div>
              ):(
                <div style={{display:'none'}}></div>
              )}
              {isCustom ? (
                <div className='w3-third w3-center w3-padding' style={{height:50}}>
                  <button className='w3-button w3-red w3-round' style={{display:'block'}} value={custom} onClick={(e) => handleSection(e)}>{custom}</button>
                </div>
              ):(
                <div style={{display:'none'}}></div>
              )}
            </div>
            <div className='w3-container w3-center'><h3>Point of Contact</h3></div>
            <div className='w3-row-padding w3-margin'>
              <div className='w3-third'><label>Name 1: </label><span>{name1}</span></div>
              <div className='w3-third'><label>Email 1: </label><span>{email1}</span></div>
              <div className='w3-third'><label>Phone 1: </label><span>{phone1}</span></div>
            </div>
            <div className='w3-row-padding w3-margin w3-border-top'>
              <div className='w3-third'><label>Name 2: </label><span>{name2}</span></div>
              <div className='w3-third'><label>Email 2: </label><span>{email2}</span></div>
              <div className='w3-third'><label>Phone 2: </label><span>{phone2}</span></div>
            </div>
          </div>
          <div className='w3-display-container w3-animate-bottom' style={dashboardStyle}>
            <img className='w3-round-large w3-opacity-min' src={background} style={{width:'100%'}} alt='background' />
            <div className='w3-display-topmiddle w3-center' style={{width:'80%'}}>
              <div className='w3-container w3-margin w3-center'>
                <div><label>Guest Information:</label></div>
                <div className='w3-container w3-eventura' style={{height:200}}></div>
              </div>
              <div className='w3-container w3-margin w3-center'>
                <div><label>Invite Guests:</label></div>
                <div className='w3-container w3-eventura' style={{height:200}}>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewEvent;
